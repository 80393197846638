import React from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
} from '@mui/material';

export default function Select(props) {
    const {
        name,
        label,
        value,
        error = null,
        onChange,
        options = [],
        disabled = false,
        variant = 'outlined',
        ...other
    } = props;
    return (
        <>
            <FormControl
                variant={variant}
                fullWidth
                {...(error && { error: true })}
                {...other}
            >
                <InputLabel>{label}</InputLabel>
                <MuiSelect
                    label={label}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option.id || option.value}
                            value={option.id || option.value}
                        >
                            {option.title || option.label}
                        </MenuItem>
                    ))}
                </MuiSelect>
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </>
    );
}
