import React, { useState, useEffect } from 'react';
import { useData } from '../../Context/Context';
import PageContentHeader from '../../Components/PageModules/PageContentHeader';
import PageContent from '../../Components/PageModules/PageContent';
import API from '../../libs/ServerAPIs';
import DataTable from '../../Components/DataTable';
import {
    Typography,
    Box,
    Container,
    Grid,
    Switch,
    FormControlLabel,
} from '@mui/material';
import OverviewCard from './Components/OverviewCard';
import ModalTrigger from './Components/ModalTrigger';
import ModalTriggers from './Components/ModalTriggers';

function Triggers() {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const { frame } = useData(useData);

    const [, set_visibleFrame] = frame;
    const [overview, setOverview] = useState(null);
    const [overviewLoading, setOverviewLoading] = useState(false);
    const [openMdlTriggerDetails, setOpenMdlTriggerDetails] = useState(false);
    const [integrationTriggerID, setIntegrationTriggerID] = useState(null);
    const [integrationTriggerPONumber, setIntegrationTriggerPONumber] =
        useState(null);
    const [openMdlTriggers, setOpenMdlTriggers] = useState(false);
    const [cardStatus, setCardStatus] = useState(null);
    const [cardTitle, setCardTitle] = useState(null);
    const [isLiveUpdateOn, setIsLiveUpdateOn] = useState(true);
    const [refreshData, setRefreshData] = useState(false);

    const component = Triggers.name;

    /* EVENT LISTENERS
     -------------------------------------------------------------------------------------*/
    useEffect(() => {
        set_visibleFrame(true);
        getIntegrationOverview();
    }, []);

    useEffect(() => {
        let timeout;

        if (isLiveUpdateOn) {
            timeout = setTimeout(() => {
                setRefreshData((prevState) => !prevState);
                getIntegrationOverview();
            }, 30 * 1000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [isLiveUpdateOn, refreshData]);

    function closeMdlTriggerDetails() {
        setOpenMdlTriggerDetails(false);
        setIntegrationTriggerID(null);
        setIntegrationTriggerPONumber(null);
    }

    function openIntegrationTriggerByID(id, data) {
        setIntegrationTriggerID(id);
        setIntegrationTriggerPONumber(data?.poNumber);
        setOpenMdlTriggerDetails(true);
    }

    function closeMdlTriggers() {
        setOpenMdlTriggers(false);
        setCardStatus(null);
    }

    function openIntegrationTriggers(status, title) {
        setCardStatus(status);
        setCardTitle(title);
        setOpenMdlTriggers(true);
    }

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/
    async function getIntegrationOverview() {
        setOverviewLoading(true);
        const api_url = '/api/v1/integration-triggers/overview';

        try {
            const res = await API.get(api_url);
            if (res && !res.error) {
                setOverview(res);
                return setOverviewLoading(false);
            } else {
                return setOverviewLoading(false);
            }
        } catch (error) {
            setOverview(null);
            // console.log(error);
            return setOverviewLoading(false);
        }
    }

    async function getAllIntegrationTriggers({
        varSearch = '',
        varOrderBy = 'created_on',
        varOrder = 'desc',
        varPageNum = 0,
        varPerPage = 10,
        varOffSet = 0,
        varTimeRangeFilter,
    }) {
        let api_url = '/api/v1/integration-triggers?';
        api_url += `search=${varSearch}&pageNum=${varPageNum}&perPage=${varPerPage}&sortBy=${varOrderBy}&order=${varOrder}&offSet=${varOffSet}${varTimeRangeFilter}`;

        API.PendingRequestsManager.registerRequest(
            component,
            'getAllIntegrationTriggers',
            api_url
        );

        try {
            const res = await API.get(api_url);
            return res;
        } catch (error) {
            return { error: true };
        }
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function onChangeLiveUpdated() {
        setIsLiveUpdateOn((prevState) => !prevState);
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <>
            <PageContent>
                <Container maxWidth="xl">
                    <PageContentHeader>
                        <Box
                            sx={{
                                width: '100%',
                                display: { md: 'flex', sm: 'block' },
                            }}
                        >
                            <Typography sx={{ flex: '1' }} variant="h2">
                                Triggers
                            </Typography>
                            <Box
                                sx={{
                                    '& .MuiButton-root': {
                                        ml: { md: '8px', xs: '0px' },
                                        mr: { md: '0px', xs: '8px' },
                                        mt: { md: '0px', xs: '8px' },
                                        width: { sm: 'auto', xs: '100%' },
                                        display: {
                                            sm: 'inline-flex',
                                            xs: 'block',
                                        },
                                    },
                                    '& .MuiButton-startIcon': {
                                        display: {
                                            xs: 'inline-flex',
                                            sm: 'inherit',
                                        },
                                        position: { xs: 'relative' },
                                        top: { xs: '4px', sm: '0px' },
                                    },
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={onChangeLiveUpdated}
                                            checked={isLiveUpdateOn}
                                        />
                                    }
                                    label="Live Updates"
                                />
                            </Box>
                        </Box>
                    </PageContentHeader>

                    <Box sx={{ mb: '24px' }}>
                        <Grid container spacing={{ xs: 2, sm: 3 }}>
                            <Grid item md={3} sm={6} xs={12}>
                                <OverviewCard
                                    total={
                                        (overview &&
                                            Object.prototype.hasOwnProperty.call(
                                                overview,
                                                'requested'
                                            ) &&
                                            overview.requested) ||
                                        0
                                    }
                                    title="Requested"
                                    severity="info"
                                    loading={overviewLoading}
                                    handleClick={() =>
                                        openIntegrationTriggers(
                                            'all',
                                            'Today Triggers: Received'
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <OverviewCard
                                    total={
                                        (overview &&
                                            Object.prototype.hasOwnProperty.call(
                                                overview,
                                                'active'
                                            ) &&
                                            overview.active) ||
                                        0
                                    }
                                    title="In-Progress"
                                    severity="warning"
                                    loading={overviewLoading}
                                    handleClick={() =>
                                        openIntegrationTriggers(
                                            'in-progress',
                                            'Today Triggers: In-Progress'
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <OverviewCard
                                    total={
                                        (overview &&
                                            Object.prototype.hasOwnProperty.call(
                                                overview,
                                                'completed'
                                            ) &&
                                            overview.completed) ||
                                        0
                                    }
                                    title="Completed"
                                    severity="success"
                                    loading={overviewLoading}
                                    handleClick={() =>
                                        openIntegrationTriggers(
                                            'completed',
                                            'Today Triggers: Completed With Success'
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <OverviewCard
                                    total={
                                        (overview &&
                                            Object.prototype.hasOwnProperty.call(
                                                overview,
                                                'completedWithError'
                                            ) &&
                                            overview.completedWithError) ||
                                        0
                                    }
                                    title="Error"
                                    severity="error"
                                    loading={overviewLoading}
                                    handleClick={() =>
                                        openIntegrationTriggers(
                                            'error',
                                            'Today Triggers: Completed With Error'
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ display: 'block' }}>
                        <Grid item lg={12}>
                            <DataTable
                                headCells={[
                                    {
                                        key: 'poNumber',
                                        orderBy: 'po_number',
                                        label: 'PO Number',
                                    },
                                    {
                                        key: 'orderNumber',
                                        orderBy: 'order_number',
                                        label: 'Order Number',
                                    },
                                    {
                                        key: 'locationNumber',
                                        orderBy: 'location_number',
                                        label: 'Location Number',
                                    },
                                    {
                                        key: 'locationName',
                                        orderBy: 'name',
                                        label: 'Location Name',
                                    },
                                    {
                                        key: 'status',
                                        orderBy: 'status',
                                        label: 'Status',
                                    },
                                    {
                                        key: 'receivedAt',
                                        orderBy: 'received_at',
                                        label: 'Received On',
                                    },
                                ]}
                                primaryKey={'integrationTriggerID'}
                                tableCallBack={getAllIntegrationTriggers}
                                sendRowData={true}
                                rowCallBack={openIntegrationTriggerByID}
                                defaultOrder={'desc'}
                                defaultOrderBy={'received_at'}
                                defaultPerPage={10}
                                onUpdate={refreshData}
                                toolbar={{
                                    searchField: true,
                                    timeRange: true,
                                }}
                            />
                        </Grid>
                    </Box>
                </Container>
            </PageContent>

            <ModalTrigger
                openModal={openMdlTriggerDetails}
                closeModal={closeMdlTriggerDetails}
                integrationTriggerID={integrationTriggerID}
                poNumber={integrationTriggerPONumber}
            />

            <ModalTriggers
                openModal={openMdlTriggers}
                closeModal={closeMdlTriggers}
                status={cardStatus}
                title={cardTitle}
                timeRange={'today'}
            />
        </>
    );
}

export default Triggers;
