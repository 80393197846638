import React from 'react';
import Spinner from '../../../../Components/Spinner';
import { Box, Card, Alert, AlertTitle } from '@mui/material';

function OverviewCard({
    title = '',
    total = 0,
    loading,
    handleClick = null,
    severity = '',
}) {
    return (
        <Card
            sx={{
                height: '100%',
                minHeight: '125px',
                cursor: handleClick ? 'pointer' : 'default',
                position: 'relative',
            }}
            onClick={handleClick}
        >
            <Alert
                severity={severity}
                sx={{
                    height: '100%',
                    boxSizing: 'border-box',
                    '& .MuiAlert-message': { flex: '1' },
                    '& .MuiAlert-icon': { fontSize: '28px' },
                }}
            >
                <AlertTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontSize: '18px',
                    }}
                >
                    {title}
                </AlertTitle>
                {total === 0 ? (
                    <Box
                        sx={{
                            fontSize: '45px',
                            textAlign: 'center',
                            pr: '34px',
                        }}
                    >
                        <strong>{0}</strong>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ fontSize: '36px', marginRight: '54px' }}>
                            <strong>{total}</strong>
                        </Box>
                    </Box>
                )}
            </Alert>
            {loading && <Spinner />}
        </Card>
    );
}

export default OverviewCard;
