import React from 'react';
import Controls from '../../../Controls/Controls';
import { Box } from '@mui/material';

function CategoryFilter({ options = [], varOption, setOption }) {
    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Box
            className="Category-filter-tool"
            sx={{
                minWidth: '170px',
                ml: { sm: '8px' },
                mt: { xs: '8px', sm: '0' },
                flexGrow: { xs: '1', sm: '0' },
            }}
        >
            <Controls.Select
                name="document_category"
                label="Category"
                sx={{ backgroundColor: '#ffffff', borderRadius: '4px' }}
                onChange={(event) => setOption(event.target.value)}
                value={(options.length && varOption) || ''}
                options={options}
            />
        </Box>
    );
}

export default CategoryFilter;
