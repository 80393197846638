import React, { useState, useEffect } from 'react';
// import DateRangePicker from '../DateRangePicker';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
// import Controls from '../Controls/Controls';
import {
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
    IconButton,
    Menu,
    Typography,
    TextField,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

function FilterTimeRangeDropdown({
    setFilterValue,
    var_timeRange = '',
    loading = false,
    pastLimitMonths,
}) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // const [dateError, setDateError] = useState(false);
    const [openStart, setOpenStart] = useState(false);
    const [openEnd, setOpenEnd] = useState(false);
    const [var_time_range, set_time_range] = useState('7_days');
    const [var_more_menu, set_more_menu] = useState(null);
    const isMoreMenuOpen = Boolean(var_more_menu);

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        set_time_range(
            (var_timeRange &&
                var_timeRange.startsWith('&timeRange=') &&
                var_timeRange.replace('&timeRange=', '')) ||
                (var_timeRange &&
                    var_timeRange.startsWith('&startDate=') &&
                    'Custom') ||
                '30_days'
        );

        const startDateValue =
            (var_timeRange &&
                var_timeRange.startsWith('&startDate=') &&
                moment(
                    new Date(
                        var_timeRange.split('=')[1].replace('&endDate', '')
                    )
                )) ||
            null;

        setStartDate(startDateValue);

        const endDateValue =
            (var_timeRange &&
                var_timeRange.startsWith('&startDate=') &&
                moment(new Date(var_timeRange.split('=')[2]))) ||
            null;

        setEndDate(endDateValue);
    }, [var_timeRange]);

    useEffect(() => {
        if (startDate && endDate && startDate <= endDate) {
            setFilterValue(
                `${
                    '&startDate=' +
                    startDate.format('YYYY-MM-DD') +
                    '&endDate=' +
                    endDate.format('YYYY-MM-DD')
                }`
            );
            handleMoreMenuClose();
        } else if (startDate && endDate) {
            setEndDate(null);
        }
    }, [startDate]);

    useEffect(() => {
        if (startDate && endDate && endDate >= startDate) {
            setFilterValue(
                `${
                    '&startDate=' +
                    startDate.format('YYYY-MM-DD') +
                    '&endDate=' +
                    endDate.format('YYYY-MM-DD')
                }`
            );
            handleMoreMenuClose();
        } else if (startDate && endDate) {
            setStartDate(null);
        }
    }, [endDate]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    const handleTimeRangeChange = (event) => {
        set_time_range(event.target.value);
        if (event.target.value !== 'Custom') {
            setFilterValue(
                `${
                    event.target.value !== ''
                        ? `&timeRange=${event.target.value}`
                        : ''
                }`
            );
            handleMoreMenuClose();
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    };

    function handleMoreMenuOpen(event) {
        set_more_menu(event.currentTarget);
    }

    function handleMoreMenuClose() {
        setOpenStart(false);
        setOpenEnd(false);
        set_more_menu(null);
    }

    function getTimeRangeLabel(varTimeRange) {
        let timeRangeLabel = '';
        switch (varTimeRange) {
            case 'All':
                timeRangeLabel = 'All';
                break;
            case 'today':
                timeRangeLabel = 'Today';
                break;
            case '7_days':
                timeRangeLabel = '7 Days';
                break;
            case '30_days':
                timeRangeLabel = '30 Days';
                break;
            case '60_days':
                timeRangeLabel = '60 Days';
                break;
            case '90_days':
                timeRangeLabel = '90 Days';
                break;
            case 'one_year':
                timeRangeLabel = '1 Year';
                break;
            case 'Custom':
                timeRangeLabel = 'By Date';
                break;
            default:
                break;
        }
        return timeRangeLabel;
    }

    // function formatDate(date) {
    //     var d = new Date(date),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();

    //     if (month.length < 2) month = '0' + month;
    //     if (day.length < 2) day = '0' + day;

    //     return [year, month, day].join('-');
    // }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Box className="TimeRangeDropdown-tool">
            <Box
                onClick={(event) => {
                    if (loading) {
                        return false;
                    }
                    handleMoreMenuOpen(event);
                }}
                sx={{
                    ...(loading && {
                        backgroundColor: '#8080802e',
                    }),
                    ...(!loading && {
                        backgroundColor: '#ffffff',
                    }),
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #bdbdbd',
                    borderRadius: '4px',
                    height: '53px',
                    boxSizing: 'border-box',
                    cursor: 'pointer',
                    p: '4px 4px 4px 16px',
                }}
            >
                <Typography
                    sx={{
                        lineHeight: '1.1',
                        mr: '4px',
                        ...(loading && {
                            color: '#a2a1a1',
                        }),
                        ...(!loading && {
                            color: 'text.secondary',
                        }),
                    }}
                >
                    Time Range
                    <Box
                        component="span"
                        sx={{
                            ...(loading && {
                                color: '#a2a1a1',
                            }),
                            ...(!loading && {
                                color: 'text.secondary',
                            }),
                            fontSize: '11px',
                            textAlign: 'center',
                            display: 'block',
                        }}
                    >
                        ({getTimeRangeLabel(var_time_range)})
                    </Box>
                </Typography>
                <IconButton disabled={loading} size="small" sx={{ ml: 'auto' }}>
                    <MoreVertIcon />
                </IconButton>
            </Box>

            <Menu
                anchorEl={var_more_menu}
                keepMounted
                open={isMoreMenuOpen}
                onClose={handleMoreMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className="filtertimerange"
            >
                <RadioGroup
                    name="filter_time"
                    value={var_time_range}
                    onChange={handleTimeRangeChange}
                    sx={{ padding: '16px' }}
                >
                    <FormControlLabel
                        value="All"
                        label="All"
                        control={<Radio />}
                    />
                    <FormControlLabel
                        value="today"
                        label="Today"
                        control={<Radio />}
                    />
                    <FormControlLabel
                        value="7_days"
                        label="7 Days"
                        control={<Radio />}
                    />
                    <FormControlLabel
                        value="30_days"
                        label="30 Days"
                        control={<Radio />}
                    />
                    <FormControlLabel
                        value="60_days"
                        control={<Radio />}
                        label="60 Days"
                    />
                    <FormControlLabel
                        value="90_days"
                        label="90 Days"
                        control={<Radio />}
                        sx={{ display: { xs: 'flex' } }}
                    />
                    <FormControlLabel
                        value="one_year"
                        label="1 Year"
                        control={<Radio />}
                        sx={{ display: { xs: 'flex' } }}
                    />
                    <FormControlLabel
                        value="Custom"
                        label="By Date"
                        control={<Radio />}
                        sx={{ display: { xs: 'flex' } }}
                    />
                </RadioGroup>
                <Box
                    sx={{
                        padding: '0 16px 8px',
                        '& > div': { display: 'block' },
                    }}
                >
                    <>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                open={openStart}
                                onOpen={() => setOpenStart(true)}
                                onClose={() => setOpenStart(false)}
                                name="startDate"
                                label="Start Date"
                                value={startDate}
                                {...(pastLimitMonths && {
                                    minDate: moment(
                                        new Date().setMonth(
                                            new Date().getMonth() -
                                                pastLimitMonths
                                        )
                                    ),
                                })}
                                disabled={var_time_range !== 'Custom'}
                                views={['year', 'month', 'day']}
                                // onChange={(date) => setStartDate(date)}
                                onAccept={(date) => setStartDate(date)}
                                slotProps={{
                                    textField: {
                                        readOnly: true,
                                        onClick: (e) => setOpenStart(true),
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={false}
                                        fullWidth
                                    />
                                )}
                            />

                            <DatePicker
                                open={openEnd}
                                onOpen={() => setOpenEnd(true)}
                                onClose={() => setOpenEnd(false)}
                                name="endDate"
                                keyboard={true}
                                label="End Date"
                                value={endDate}
                                views={['year', 'month', 'day']}
                                sx={{ mt: '8px' }}
                                disabled={var_time_range !== 'Custom'}
                                // onChange={(date) => setEndDate(date)}
                                onAccept={(date) => setEndDate(date)}
                                slotProps={{
                                    textField: {
                                        readOnly: true,
                                        onClick: (e) => setOpenEnd(true),
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={false}
                                        fullWidth
                                    />
                                )}
                                disableFuture
                            />
                        </LocalizationProvider>
                    </>
                </Box>
                <IconButton
                    onClick={handleMoreMenuClose}
                    sx={{ position: 'absolute', top: '0', right: '0' }}
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </Menu>
        </Box>
    );
}

export default FilterTimeRangeDropdown;
