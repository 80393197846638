import React, { useEffect, useState, Fragment } from 'react';
import Modal from '../../../../Components/Modal';
import API from '../../../../libs/ServerAPIs';
import {
    Button,
    Box,
    Slide,
    CircularProgress,
    Typography,
    Container,
    Stepper,
    Grid,
} from '@mui/material';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Step, StepLabel, styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';

import DataTable from '../../../../Components/DataTable';
import { useData } from '../../../../Context/Context';

const steps = [
    {
        value: 0,
        label: 'PestPac Web Hook Request',
        key: 'pestPacWebHookRequest',
    },
    {
        value: 1,
        label: 'Service Channel Work Order',
        key: 'serviceChannelWorkOrder',
    },
    { value: 2, label: 'Add Check In', key: 'addCheckIn' },
    { value: 3, label: 'Add Notes', key: 'addNote' },
    { value: 4, label: 'Add Check Out', key: 'addCheckOut' },
];

const QontoStepIconRoot = styled('div')(({ theme }) => ({
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    '& .QontoStepIcon-completedIcon': {
        color: '#4CAF50',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-warningIcon': {
        color: '#e65100',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-errorIcon': {
        color: '#D32F2F',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    ...theme.applyStyles('dark', {
        color: theme.palette.grey[700],
    }),
    variants: [
        {
            props: ({ ownerState }) => ownerState.active,
            style: {
                color: '#784af4',
            },
        },
    ],
}));

function QontoStepIcon(props) {
    const { active, completed, className, error, warning } = props;
    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed === true && !warning ? (
                <CheckCircleIcon className="QontoStepIcon-completedIcon" />
            ) : warning ? (
                <ReportProblemIcon className="QontoStepIcon-warningIcon" />
            ) : error ? (
                <CancelIcon className="QontoStepIcon-errorIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

function ModalTrigger({
    openModal,
    closeModal,
    integrationTriggerID,
    poNumber,
}) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const { userData } = useData(useData);

    const [user_details] = userData;
    const [activeStep, setActiveStep] = useState(0);
    const [activeKey, setActiveKey] = useState('pestPacWebHookRequest');
    const [activeLabel, setActiveLabel] = useState('PestPac Web Hook Request');
    const [integrationTrigger, setIntegrationTrigger] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reqLoading, setReqLoading] = useState(false);
    const [refreshData, setRefreshData] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const component = ModalTrigger.name;

    /* EVENT LISTENERS
     -------------------------------------------------------------------------------------*/

    useEffect(() => {
        if (openModal && integrationTriggerID) {
            getIntegrationTrigger(integrationTriggerID);
        }

        return () => {
            API.PendingRequestsManager.abortRequest(
                component,
                'getIntegrationTrigger'
            );
            setIntegrationTrigger(null);
            return setLoading(true);
        };
    }, [openModal, integrationTriggerID]);

    const handleStep = (value, key, label) => {
        setActiveStep(value);
        setActiveKey(key);
        setActiveLabel(label);
    };

    const checkIsFailed = (key, checkDisabled = false) => {
        if (
            integrationTrigger &&
            Object.prototype.hasOwnProperty.call(
                integrationTrigger?.response,
                key
            ) &&
            Object.prototype.hasOwnProperty.call(
                integrationTrigger?.response[key],
                'error'
            )
        ) {
            return true;
        }
        if (checkDisabled) {
            return checkIsDisabled(key);
        }
        return false;
    };

    const getMessageColor = (key) => {
        if (
            integrationTrigger &&
            Object.prototype.hasOwnProperty.call(
                integrationTrigger?.response,
                key
            ) &&
            Object.prototype.hasOwnProperty.call(
                integrationTrigger?.response[key],
                'error'
            )
        ) {
            return 'error';
        }

        if (
            integrationTrigger &&
            key === 'serviceChannelWorkOrder' &&
            integrationTrigger?.status === 'CompletedWithWarning'
        ) {
            return '#e65100';
        }

        return 'black';
    };

    const checkIsDisabled = (key) => {
        if (
            key &&
            integrationTrigger &&
            Object.prototype.hasOwnProperty.call(
                integrationTrigger.request,
                key
            )
        ) {
            return false;
        }
        return true;
    };

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/
    async function getIntegrationTrigger(id) {
        setLoading(true);
        const api_url = `/api/v1/integration-triggers/${id}`;

        API.PendingRequestsManager.registerRequest(
            component,
            'getIntegrationTrigger',
            api_url
        );

        return API.get(api_url).then((res) => {
            if (res && !res.error) {
                setIntegrationTrigger(res);
            }
            setLoading(false);
        });
    }

    async function getIntegrationTriggerHistory({
        varPageNum = 0,
        varPerPage = 10,
        varOffSet = 0,
    }) {
        let api_url = '/api/v1/logger/history?integrationTriggerID=';
        api_url += `${integrationTriggerID}&pageNum=${varPageNum}&perPage=${varPerPage}&offSet=${varOffSet}`;

        API.PendingRequestsManager.registerRequest(
            component,
            'getIntegrationTriggerHistory',
            api_url
        );

        try {
            const res = await API.get(api_url);
            return res;
        } catch (error) {
            return { error: true };
        }
    }

    async function submitManualRequest() {
        setReqLoading(true);
        const requestUrl = `/api/v1/integration-triggers/${integrationTriggerID}`;

        API.PendingRequestsManager.registerRequest(
            component,
            'submitManualRequest',
            requestUrl
        );

        await API.put(requestUrl, {}).then((res) => {
            if (res && !res.error) {
                setIntegrationTrigger(res);
                setRefreshData((prevState) => !prevState);

                setReqLoading(false);
            } else {
                showSnackbar('error', res.message);
                setReqLoading(false);

                if (res && res.reload) {
                    getIntegrationTrigger(integrationTriggerID);
                }
            }
        });
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function showSnackbar(variant, message) {
        enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            TransitionComponent: Slide,
        });
    }

    function onClose() {
        closeModal();
        setActiveStep(0);
        setActiveKey('pestPacWebHookRequest');
        setActiveLabel('PestPac Web Hook Request');
    }

    function handleSubmitManualRequest() {
        submitManualRequest();
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Modal
            title={`Trigger Details: Purchase Order - ${poNumber}`}
            openModal={openModal}
            closeModal={onClose}
            width="lg"
            sx={{
                '& > .MuiDialogActions-root': {
                    justifyContent: { xs: 'center' },
                },
            }}
            dialogActions={
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: {
                            xs: 'center',
                            sm: 'flex-end',
                        },
                        alignItems: 'center',
                        '& > .MuiDialogActions-root': {
                            justifyContent: { xs: 'center' },
                        },
                    }}
                >
                    {(user_details?.isSuperAdmin === true ||
                        user_details?.isSpragueAdmin === true) &&
                        integrationTrigger !== null &&
                        integrationTrigger?.status !== 'CompletedWithSuccess' &&
                        integrationTrigger?.status !== 'In-Progress' && (
                            <LoadingButton
                                sx={{ mr: { md: '8px', sm: '2px' } }}
                                variant="contained"
                                color="secondary"
                                loading={loading || reqLoading}
                                disabled={loading || reqLoading}
                                onClick={handleSubmitManualRequest}
                            >
                                Check Now
                            </LoadingButton>
                        )}
                    <Button onClick={onClose}>Close</Button>
                </Box>
            }
        >
            {loading && (
                <Box sx={{ textAlign: 'center' }}>
                    <CircularProgress />
                </Box>
            )}

            {!loading && (
                <Container maxWidth="xl">
                    <Box sx={{ width: '100%' }}>
                        <Stepper nonLinear activeStep={activeStep}>
                            {steps.map((step) => (
                                <Step
                                    key={step.label}
                                    completed={!checkIsFailed(step.key, true)}
                                >
                                    <StepLabel
                                        error={checkIsFailed(step.key)}
                                        StepIconComponent={QontoStepIcon}
                                        StepIconProps={{
                                            error: checkIsFailed(step.key),
                                            warning:
                                                getMessageColor(step.key) ===
                                                '#e65100',
                                            completed: !checkIsFailed(
                                                step.key,
                                                true
                                            ),
                                        }}
                                        sx={{
                                            color: 'red',
                                            cursor: checkIsDisabled(step.key)
                                                ? 'default'
                                                : 'pointer',
                                            background:
                                                activeStep === step.value
                                                    ? '#80808042'
                                                    : '',
                                            padding: '16px 8px',
                                            borderRadius: '4px',
                                        }}
                                        onClick={() => {
                                            if (
                                                loading ||
                                                checkIsDisabled(step.key)
                                            ) {
                                                return false;
                                            } else {
                                                handleStep(
                                                    step.value,
                                                    step.key,
                                                    step.label
                                                );
                                            }
                                        }}
                                    >
                                        {step.label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeKey !== '' && integrationTrigger !== null && (
                            <Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    {activeLabel} <br></br>
                                    <b>
                                        {integrationTrigger?.request[activeKey]
                                            ?.to || ''}
                                    </b>{' '}
                                    <br></br>
                                    <Typography
                                        variant="caption"
                                        color={getMessageColor(activeKey)}
                                    >
                                        {integrationTrigger?.response[activeKey]
                                            ?.message || ''}
                                    </Typography>
                                </Typography>
                                <Box
                                    display="flex"
                                    gap={3}
                                    alignItems={'stretch'}
                                >
                                    <Box width={'50%'}>
                                        <Typography variant="h5">
                                            Request:
                                        </Typography>
                                        <Box>
                                            <Box
                                                sx={{
                                                    background: 'grey',
                                                    padding: '4',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        textWrap: 'wrap',
                                                    }}
                                                >
                                                    <pre
                                                        style={{
                                                            whiteSpace: 'pre',
                                                            fontWeight: '500',
                                                            color: 'white',
                                                            height: '400px',
                                                            overflow: 'auto',
                                                        }}
                                                    >
                                                        {JSON.stringify(
                                                            integrationTrigger
                                                                ?.request[
                                                                activeKey
                                                            ]?.body || {},
                                                            null,
                                                            2
                                                        )}
                                                    </pre>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box width={'50%'}>
                                        <Typography variant="h5">
                                            Response:
                                        </Typography>
                                        <Box>
                                            <Box
                                                sx={{
                                                    background: 'grey',
                                                    padding: '4',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        textWrap: 'wrap',
                                                    }}
                                                >
                                                    <pre
                                                        style={{
                                                            whiteSpace: 'pre',
                                                            fontWeight: '500',
                                                            color: 'white',
                                                            height: '400px',
                                                            overflow: 'auto',
                                                        }}
                                                    >
                                                        {JSON.stringify(
                                                            integrationTrigger
                                                                ?.response[
                                                                activeKey
                                                            ]?.data || {},
                                                            null,
                                                            2
                                                        )}
                                                    </pre>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Fragment>
                        )}
                    </Box>

                    <Box sx={{ display: 'block' }}>
                        <Typography variant="h5" sx={{ pb: 2 }}>
                            History
                        </Typography>
                        <Grid item lg={12}>
                            <DataTable
                                headCells={[
                                    {
                                        key: 'action',
                                        orderBy: null,
                                        label: 'Action',
                                    },
                                    {
                                        key: 'description',
                                        orderBy: null,
                                        label: 'Description',
                                    },
                                    {
                                        key: 'time',
                                        orderBy: null,
                                        label: 'Activity At',
                                    },
                                ]}
                                primaryKey={'userActivityLogID'}
                                tableCallBack={getIntegrationTriggerHistory}
                                onUpdate={refreshData}
                                defaultPerPage={7}
                            />
                        </Grid>
                    </Box>
                </Container>
            )}
        </Modal>
    );
}

export default ModalTrigger;
