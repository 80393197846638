import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Select, MenuItem } from '@mui/material';

function LoadMore({
    loading,
    loadMoreCount,
    onChangeLoadCount,
    onClickLoadMore,
    total,
    showing,
}) {
    return (
        <Box
            className="loadMoreContainer"
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '20px 8px',
            }}
        >
            <Box sx={{ mb: '8px', fontSize: '12px' }}>
                Showing {showing} of {total}
            </Box>
            {showing < total && (
                <Box
                    sx={{
                        backgroundColor: '#f6f8fa',
                        display: 'flex',
                        border: 1,
                        borderRadius: 1,
                        borderColor: '#bfcedc',
                        '& .MuiSelect-select': {
                            border: 'none',
                            paddingTop: '12px',
                            paddingBottom: '12px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                >
                    <Select
                        value={loadMoreCount}
                        disabled={loading}
                        onChange={onChangeLoadCount}
                    >
                        {/* <MenuItem value={'all'}>All</MenuItem> */}
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={75}>75</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={250}>250</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                    </Select>
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        onClick={onClickLoadMore}
                        sx={{
                            borderRadius: 0,
                            borderLeft: '1px solid #bfcedc',
                            px: 2,
                        }}
                    >
                        Load More
                    </LoadingButton>
                </Box>
            )}
        </Box>
    );
}

export default LoadMore;
