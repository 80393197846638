import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Box, AppBar, Toolbar, IconButton, Badge } from '@mui/material';
import UserMenu from './UserMenu';
import MobileMenu from './MobileMenu';
import Logo from '../../logo.png';
import LastLoggedIn from './LastLoggedIn';
import NotificationMenu from './NotificationMenu';
import API from '../../libs/ServerAPIs';
import {
    getRecentActivityList,
    markActivitiesAsRead,
} from '../../libs/activityLog';
import { useData } from '../../Context/Context';

function AppTopBar({
    drawerToggle,
    openMdlNotifications,
    closeMdlNotifications,
    openMdlPreferences,
}) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const { auth, activeSession, userData, appTopBarTitleAsPerPage } =
        useData(useData);
    const [user_details] = userData;
    const [isActiveSession] = activeSession;

    const [app_top_bar_title, set_app_top_bar_title] = appTopBarTitleAsPerPage;
    const [isAuth] = auth;
    const [var_user_menu_elem, set_user_menu_elem] = useState(null);
    const isMenuOpen = Boolean(var_user_menu_elem);
    const [var_mobile_moreEl, set_mobile_moreEl] = useState(null);
    const isMobileMenuOpen = Boolean(var_mobile_moreEl);
    const [notifications, setNotifications] = useState([]);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [prevNotificationsCount, setPrevNotificationsCount] = useState(0);
    const [totalNotificationsCount, setTotalNotificationsCount] = useState(0);
    const initialTotal = useRef(0);
    const [var_notification_el, set_notification_el] = useState(null);
    const isNotificationsOpen = Boolean(var_notification_el);
    const location = useLocation();
    const history = useNavigate();

    const component = AppTopBar.name;

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        switch (location.pathname) {
            case '/app':
                set_app_top_bar_title('Dashboard');
                break;
            case '/app/manage-users':
                set_app_top_bar_title('Manage Users');
                break;
            case '/app/integrations':
                set_app_top_bar_title('Integrations');
                break;
            case '/app/settings':
                set_app_top_bar_title('App Settings');
                break;
            case '/app/reports':
                set_app_top_bar_title('Reports');
                break;
            case '/app/triggers':
                set_app_top_bar_title('Triggers');
                break;
            default:
                set_app_top_bar_title('');
                break;
        }
    }, [location.pathname]);

    useEffect(() => {
        let fetchInterval;
        let fetchNotifications;
        if (isAuth) {
            fetchNotifications = fetchNewNotifications;
            fetchInterval = setInterval(fetchNotifications, 1000 * 60 * 15);
        }

        return () => {
            if (fetchInterval) {
                clearInterval(fetchInterval);
            }
            if (fetchNotifications?.name) {
                API.PendingRequestsManager.abortRequest(
                    component,
                    fetchNotifications?.name
                );
            }
        };
    }, [isActiveSession]);

    useEffect(() => {
        const recentNotifications = getRecentActivityList();
        setNotifications(recentNotifications);
        setNotificationsCount(recentNotifications.length);
    }, [sessionStorage.activityCount]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/
    async function getNotifications() {
        const requestUrl = '/api/v1/logger';
        API.PendingRequestsManager.registerRequest(
            component,
            'getNotifications',
            requestUrl
        );
        const logger = await API.get(requestUrl);
        return logger;
    }

    async function fetchNewNotifications() {
        const userNotifications = await getNotifications();
        if (userNotifications.data) {
            setNotifications(userNotifications.data);
            if (initialTotal.current === 0) {
                initialTotal.current = userNotifications.pagination.total;
            } else if (initialTotal.current > 0) {
                setTotalNotificationsCount(userNotifications.pagination.total);
                const countDiff =
                    userNotifications.pagination.total - initialTotal.current;
                if (countDiff > 0) {
                    setNotificationsCount(countDiff);
                }
            }
        }
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function handleUserMenuOpen(event) {
        set_user_menu_elem(event.currentTarget);
    }

    function handleMobileMenuOpen(event) {
        set_mobile_moreEl(event.currentTarget);
    }

    function handleMobileMenuClose() {
        set_mobile_moreEl(null);
    }

    function handleMenuClose() {
        set_user_menu_elem(null);
        handleMobileMenuClose();
    }

    function handleNotificationMenuOpen(event) {
        set_notification_el(event.currentTarget);
        resetNotificationsCount();
    }

    function handleNotificationMenuClose() {
        set_notification_el(null);
        handleMobileMenuClose();
    }

    function resetNotificationsCount() {
        markActivitiesAsRead();
        setNotificationsCount(0);
        setPrevNotificationsCount(notificationsCount);
        initialTotal.current = totalNotificationsCount;
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color="primary">
                <Toolbar sx={{ alignItems: 'stretch', px: '0 !important' }}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            ml: '0',
                            mr: { xs: 0, md: 2 },
                            display: { lg: 'none' },
                        }}
                        onClick={drawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box
                        onClick={() => history('/app')}
                        sx={{
                            cursor: 'pointer',
                            height: { xs: '56px', sm: '64px' },
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxSizing: 'border-box',
                            borderBottom: { lg: '1px solid #2c4459' },
                            width: { lg: '240px' },
                            backgroundColor: { lg: 'primary.dark' },
                            '& > img': {
                                maxWidth: { xs: '90px', md: '140px' },
                                '@media (max-width: 360px)': {
                                    maxWidth: '72px',
                                },
                            },
                        }}
                    >
                        <img src={Logo} alt="Sprague Customer Portal" />
                    </Box>

                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            alignItems: 'center',
                            pl: '24px',
                        }}
                    >
                        <Box>
                            <Box sx={{ color: '#fff', fontSize: '20px' }}>
                                {app_top_bar_title}
                            </Box>
                            <Box
                                sx={{
                                    color: 'rgba(255,255, 255, 0.75)',
                                    fontSize: '12px',
                                    display: 'flex',
                                    mt: '2px',
                                    letterSpacing: '0.75px',
                                    '& > div': { mr: '8px' },
                                }}
                            >
                                <Box>
                                    {user_details
                                        ? `${user_details.firstName} ${user_details.lastName}`
                                        : ''}
                                </Box>
                                <Box>
                                    {user_details ? user_details.email : ''}
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'block' },
                        }}
                    />

                    <Box
                        component="ul"
                        sx={{
                            display: 'flex',
                            listStyle: 'none',
                            p: 0,
                            m: 0,
                            ml: 'auto',
                            '& > li': {
                                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                                boxShadow:
                                    'inset 1px 0 rgba(255, 255, 255, 0.12)',
                            },
                        }}
                    >
                        <Box
                            component="li"
                            sx={{ display: { xs: 'none', md: 'block' } }}
                        >
                            <LastLoggedIn userMenu={false} />
                        </Box>
                        <Box
                            component="li"
                            sx={{ display: { xs: 'none', lg: 'flex' } }}
                        >
                            <IconButton
                                size="large"
                                color="inherit"
                                onClick={handleNotificationMenuOpen}
                            >
                                <Badge
                                    badgeContent={notificationsCount}
                                    color="error"
                                >
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Box>
                        <Box
                            component="li"
                            sx={{ display: { xs: 'none', lg: 'flex' } }}
                        >
                            <IconButton
                                onClick={handleUserMenuOpen}
                                color="inherit"
                                size="large"
                            >
                                <AccountCircleIcon />
                            </IconButton>
                        </Box>
                        <Box
                            component="li"
                            sx={{ display: { xs: 'flex', lg: 'none' } }}
                        >
                            <IconButton
                                size="large"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            <MobileMenu
                anchorEl={var_mobile_moreEl}
                handleMenuOpen={isMobileMenuOpen}
                handleMobileMenuClose={handleMobileMenuClose}
                handleUserMenuOpen={handleUserMenuOpen}
                handleNotificationMenuOpen={handleNotificationMenuOpen}
                notificationsList={notifications}
            />

            <NotificationMenu
                anchorEl={var_notification_el}
                handleMenuOpen={isNotificationsOpen}
                handleMenuClose={handleNotificationMenuClose}
                openMdlNotifications={openMdlNotifications}
                closeMdlNotifications={closeMdlNotifications}
                notificationsList={notifications}
                notificationsCount={prevNotificationsCount}
                newNotificationsCount={notificationsCount}
                totalNotifications={totalNotificationsCount}
            />

            <UserMenu
                anchorEl={var_user_menu_elem}
                handleMenuOpen={isMenuOpen}
                handleMenuClose={handleMenuClose}
                openMdlPreferences={openMdlPreferences}
            />
        </Box>
    );
}

AppTopBar.defaultProps = {
    drawerToggle: () => {},
    openMdlNotifications: () => {},
    closeMdlNotifications: () => {},
};

AppTopBar.propTypes = {
    drawerToggle: PropTypes.func,
    openMdlNotifications: PropTypes.func,
    closeMdlNotifications: PropTypes.func,
};

export default AppTopBar;
