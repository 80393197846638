import React, { useState, useEffect } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Menu, MenuItem, Box, Divider, Typography } from '@mui/material';
import moment from 'moment';
import { useData } from '../../../Context/Context';

function NotificationMenu({
    anchorEl,
    handleMenuOpen,
    handleMenuClose,
    openMdlNotifications,
    notificationsList,
    notificationsCount = 0,
    newNotificationsCount = 0,
    totalNotifications = 0,
}) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const { userData } = useData(useData);
    const [user_details] = userData;
    const [notifications, setNotifications] = useState([]);

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        const newNotificationsList = notificationsList.slice(
            0,
            newNotificationsCount >= notificationsCount
                ? newNotificationsCount
                : notificationsCount
        );
        setNotifications(newNotificationsList);
    }, [
        handleMenuOpen,
        notificationsCount,
        newNotificationsCount,
        totalNotifications,
    ]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* RENDER APP
    -------------------------------------------------------------------------------------*/

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={handleMenuOpen}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                component="div"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    sx: {
                        '& .MuiMenuItem-root': {
                            whiteSpace: 'normal',
                            py: '8px',
                        },
                    },
                }}
                sx={{
                    '& .MuiMenu-list': {
                        p: '0',
                        minWidth: '300px',
                        maxWidth: '300px',
                    },
                }}
            >
                <MenuItem sx={{ py: '20px !important' }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h4">Notifications</Typography>
                        <Box sx={{}}>
                            You have {notifications.length} new notifications
                        </Box>
                    </Box>
                </MenuItem>
                <Divider sx={{ m: '0 !important' }} />
                <Box
                    sx={{
                        maxHeight: '290px',
                        overflow: 'hidden',
                        overflowY: 'auto',
                    }}
                >
                    {notifications.map((notification, key) => (
                        <MenuItem key={key}>
                            <Box>
                                <Box
                                    sx={{ fontWeight: '700', fontSize: '16px' }}
                                >
                                    {notification.entity}
                                </Box>
                                <Typography>
                                    {user_details &&
                                        notification.user &&
                                        (user_details.role ===
                                            'Customer Admin' ||
                                            user_details.role ===
                                                'Super Admin' ||
                                            user_details.role ===
                                                'Content Manager' ||
                                            user_details.role === 'Admin') && (
                                            <span>
                                                <b>{notification.user}</b>{' '}
                                            </span>
                                        )}
                                    {notification.description} at{' '}
                                    {moment(notification.time).format('l - LT')}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Box>
                <Divider />
                <MenuItem
                    onClick={openMdlNotifications}
                    sx={{ textAlign: 'center', justifyContent: 'center' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            color: 'secondary.main',
                            py: '6px',
                        }}
                    >
                        <Box sx={{ textTransform: 'uppercase' }}>View all</Box>
                        <ArrowForwardIcon />
                    </Box>
                </MenuItem>
            </Menu>
        </>
    );
}

export default NotificationMenu;
