import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputBase } from '@mui/material';

function Search({ handleSearchChange, value }) {
    return (
        <Box
            className="Toolbar-search"
            sx={{
                boxSizing: 'border-box',
                border: 1,
                borderColor: 'grey.400',
                position: 'relative',
                borderRadius: 1,
                marginLeft: 0,
                backgroundColor: '#ffffff',
                '&:hover': {
                    borderColor: 'text.primary',
                },
            }}
        >
            <Box
                sx={{
                    py: 0,
                    px: 2,
                    height: '100%',
                    position: 'absolute',
                    pointerEvents: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <SearchIcon />
            </Box>
            <InputBase
                sx={{
                    color: 'inherit',
                    '& .MuiInputBase-input': {
                        padding: 1,
                        height: '51px',
                        boxSizing: 'border-box',
                        paddingLeft: (theme) =>
                            `calc(1em + ${theme.spacing(4)})`,
                        transition: (theme) =>
                            theme.transitions.create('width'),
                        width: { xs: '100%', md: '260px' },
                    },
                }}
                placeholder="Search..."
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleSearchChange}
                value={value}
            />
        </Box>
    );
}

export default Search;
