import React, { useState } from 'react';
import { Menu, MenuItem, Avatar, Box, Divider } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../../Context/Context';
import API from '../../../libs/ServerAPIs';
import { LoadingButton } from '@mui/lab';
import LastLoggedIn from '../LastLoggedIn';

function UserMenu({
    anchorEl,
    handleMenuOpen,
    handleMenuClose,
    openMdlPreferences,
}) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const history = useNavigate();
    const { userData, auth, activeSession, mobileCheck } = useData(useData);
    const [user_details, setUserDetails] = userData;
    const [, setIsAuth] = auth;
    const [, setIsActiveSession] = activeSession;
    const [isMobile] = mobileCheck;
    const [loading, setLoading] = useState(false);

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    const logoutClickHandler = () => {
        setLoading(true);
        API.get('/api/v1/auth/logout').then((res) => {
            if (res.message) {
                setIsAuth(false);
                setIsActiveSession(false);
                setUserDetails(null);
                localStorage.clear();
                if (res.redirectURL) {
                    window.location.replace(res.redirectURL);
                } else {
                    history('/', { replace: true });
                }
            }
        });
    };

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* RENDER APP
    -------------------------------------------------------------------------------------*/

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={handleMenuOpen}
                onClose={handleMenuClose}
                sx={{ '& .MuiMenu-list': { p: '0px' } }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    sx={{ py: '12px', minWidth: '210px', alignItems: 'center' }}
                    onClick={() => {
                        openMdlPreferences();
                        handleMenuClose();
                    }}
                >
                    <Avatar
                        sx={{ bgcolor: deepOrange[100] }}
                        alt={
                            user_details?.firstName +
                            ' ' +
                            user_details?.lastName
                        }
                        src={
                            '/api/v1/user/get-profile-picture?id=' +
                            user_details.userID +
                            '&time=' +
                            new Date(user_details.updatedOn).getTime()
                        }
                    />
                    <Box sx={{ pl: '10px' }}>
                        <Box
                            sx={{
                                fontSize: '15px',
                                fontWeight: '500',
                                textTransform: 'capitalize',
                                lineHeight: '1',
                            }}
                        >
                            {user_details?.firstName} {user_details?.lastName}
                        </Box>
                        <Box sx={{ fontSize: '12px', color: 'text.secondary' }}>
                            {user_details?.role}
                        </Box>
                    </Box>
                </MenuItem>

                <Divider sx={{ mt: '0 !important' }} />
                <MenuItem
                    sx={{ py: '8px' }}
                    onClick={() => {
                        openMdlPreferences();
                        handleMenuClose();
                    }}
                >
                    Preferences
                </MenuItem>
                {isMobile && (
                    <>
                        <Divider sx={{ mt: '0 !important' }} />
                        <MenuItem sx={{ py: '8px' }}>
                            <Box
                                component="li"
                                sx={{ '& .MuiBox-root': { color: '#455A64' } }}
                            >
                                <LastLoggedIn userMenu={true} />
                            </Box>
                        </MenuItem>
                    </>
                )}
                <MenuItem
                    disableRipple
                    sx={{
                        py: '8px',
                        mb: '8px',
                        '&:hover': { backgroundColor: 'rgba(0,0,0,0)' },
                    }}
                >
                    <LoadingButton
                        loading={loading}
                        disabled={false}
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            logoutClickHandler();
                        }}
                    >
                        Log Out
                    </LoadingButton>
                </MenuItem>
            </Menu>
        </>
    );
}

export default UserMenu;
