import React, { useEffect, useState } from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Chip,
    OutlinedInput,
    Box,
    Checkbox,
    ListItemText,
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    variant: 'menu',
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const allOption = { id: 'all', label: 'All' };

function CustomizableMultiSelect({
    label = 'Multi Select',
    defaultValue = null,
    name = 'multi_select',
    value = null,
    onChange = {},
    options = [],
    useWithUseForm = false,
    selectAll = false,
    onCloseMaintainedOldState = false,
    clear = false,
    chip = true,
    error = null,
    disabled = false,
    variant = 'outlined',
    ...other
}) {
    // Methods --------------------------------------------------------------
    //   In component flag for when all options are selected
    const checkIfAllSelected = (clearValue = false) => {
        if (
            Array.isArray(current_value) &&
            (current_value?.filter((i) => i.id === 'all').length > 0 ||
                clearValue) &&
            (var_firstLoad || clearValue)
        ) {
            if (!var_firstLoad || clearValue) {
                if (useWithUseForm) {
                    onChange({
                        target: { value: [value.props.value], name: name },
                    });
                } else {
                    onChange([allOption]);
                }
            }
            set_var_firstLoad(false);
        }
    };

    // const checkIfSelected = (key, value) => {
    //     if (Array.isArray(current_value)) {
    //         const flag =
    //             current_value.filter(
    //                 (i) => i.id === value.id || i.label === value.label
    //             ).length > 0;
    //         return flag;
    //     }
    //     return false;
    // };

    const changeSelection = (e, value, reason) => {
        debugger;
        if (
            !(value.props.value.id === 'all') &&
            current_value.filter((i) => i.id === 'all').length > 0
        ) {
            set_current_value([value.props.value]);
            onChange([value.props.value]);
        } else if (
            value.props.value.id === 'all' &&
            current_value.length !== available_option.length
        ) {
            set_current_value(available_option);
            if (!var_firstLoad) {
                onChange([allOption]);
            }
        } else if (
            value.props.value.id === 'all' &&
            current_value.length === available_option.length
        ) {
            set_current_value([]);
        } else {
            set_current_value(e.target.value);
            onChange(e.target.value);
        }
    };

    const handleChangeSelection = (e, value, reason) => {
        if (
            !(value.props.value.id === 'all') &&
            current_value.filter((i) => i.id === 'all').length > 0
        ) {
            set_current_value([value.props.value]);
            let simulatedEvent = {
                target: { value: [value.props.value], name: name },
            };
            onChange(simulatedEvent);
        } else if (
            value.props.value.id === 'all' &&
            current_value.length !== available_option.length
        ) {
            set_current_value([allOption]);
            onChange({ target: { value: [allOption], name: name } });
        } else if (
            value.props.value.id === 'all' &&
            current_value.length === available_option.length
        ) {
            set_current_value([]);
            onChange({ target: { value: [], name: name } });
        } else {
            set_current_value(e.target.value);
            onChange(e);
        }
    };

    const onCloseHandler = () => {
        if (!clear && onCloseMaintainedOldState && current_value.length === 0) {
            if (value.filter((i) => i.id === 'all').length > 0) {
                set_current_value(available_option);
            } else {
                set_current_value(value);
            }
        }
    };
    // Methods ---------------------------------------------------------------

    // Declarations-----------------------------------------------------------
    const [available_option, set_available_options] = useState(options);
    const [var_firstLoad, set_var_firstLoad] = useState(true);
    const [current_value, set_current_value] = useState([]);
    // Declarations-----------------------------------------------------------

    //   Event listeners---------------------------------------------------------------
    useEffect(() => {
        set_var_firstLoad(true);
        if (available_option.length > 0) {
            if (value || defaultValue) {
                if (value && Array.isArray(value) && value.length > 0) {
                    if (value.filter((i) => i.id === 'all').length > 0) {
                        set_current_value(available_option);
                        checkIfAllSelected();
                    } else if (value.length > 0) {
                        let newCurrentValue = [];
                        value.forEach((element) => {
                            let existingValue = available_option.filter(
                                (i) => i.value === element
                            );
                            if (existingValue.length > 0) {
                                newCurrentValue.push(existingValue[0].value);
                            }
                        });
                        set_current_value(newCurrentValue);
                    } else if (
                        defaultValue &&
                        Array.isArray(defaultValue) &&
                        defaultValue.length > 0
                    ) {
                        set_current_value(defaultValue);
                        set_var_firstLoad(false);
                    }
                }
            }
        }

        return () => {};
    }, [available_option]);

    useEffect(() => {
        if (clear) {
            set_current_value(available_option);
            if (selectAll) {
                checkIfAllSelected(true);
            }
            if (
                onCloseMaintainedOldState &&
                !selectAll &&
                Array.isArray(defaultValue) &&
                defaultValue.length > 0
            ) {
                set_current_value(defaultValue);
            }
        }
        return () => {};
    }, [clear]);

    useEffect(() => {
        if (selectAll) {
            options.splice(
                options.indexOf(
                    options.find(
                        (i) =>
                            i?.id === 'all' || i.label?.toLowerCase() === 'all'
                    )
                ),
                1
            );
            set_available_options([allOption, ...options]);
        } else {
            set_available_options(options);
        }
        return () => {};
    }, [options]);

    //   Event listeners---------------------------------------------------------------

    return (
        <>
            <FormControl
                variant={variant}
                fullWidth
                {...(error && { error: true })}
                {...other}
            >
                <InputLabel>{label}</InputLabel>
                <Select
                    multiple
                    name={name}
                    value={current_value}
                    onChange={(e, value, reason) => {
                        changeSelection(e, value, reason);
                    }}
                    {...(useWithUseForm
                        ? {
                              onChange: (e, value, reason) => {
                                  handleChangeSelection(e, value, reason);
                              },
                          }
                        : {
                              onChange: (e, value, reason) => {
                                  changeSelection(e, value, reason);
                              },
                          })}
                    onClose={onCloseHandler}
                    input={<OutlinedInput label={label} />}
                    renderValue={(selected) =>
                        chip ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5,
                                }}
                            >
                                {selected.map((val) => (
                                    <Chip
                                        size="small"
                                        key={val.value}
                                        label={val.label}
                                    />
                                ))}
                            </Box>
                        ) : (
                            <Box
                                component="span"
                                sx={{
                                    color: 'grey',
                                    zIndex: 51,
                                    position: { xs: 'absolute', sm: 'static' },
                                }}
                            >
                                <Box
                                    component="span"
                                    sx={{
                                        display: { xs: 'none', sm: 'inline' },
                                    }}
                                >
                                    Selected
                                </Box>{' '}
                                {`${
                                    available_option.length ===
                                    current_value.length
                                        ? 'All'
                                        : current_value.length
                                }`}
                            </Box>
                        )
                    }
                    MenuProps={MenuProps}
                    disabled={disabled}
                >
                    {available_option &&
                        available_option.map((option, index) => (
                            <MenuItem
                                key={index}
                                value={option.value || option}
                            >
                                <Checkbox
                                    checked={
                                        current_value?.indexOf(
                                            option.value || option
                                        ) > -1
                                    }
                                />
                                <ListItemText
                                    primary={option.label}
                                    secondary={option.email || false}
                                />
                            </MenuItem>
                        ))}
                </Select>
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </>
    );
}

export default CustomizableMultiSelect;
