import React, { useState } from 'react';
import Modal from '../../../../Components/Modal';
import API from '../../../../libs/ServerAPIs';
import DataTable from '../../../../Components/DataTable';
import { Box, Card, TableContainer, Button } from '@mui/material';
import ModalTrigger from '../ModalTrigger';

function ModalTriggers({
    openModal,
    closeModal,
    status = 'all',
    title = 'Requested',
    timeRange = 'today',
}) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const [openMdlTriggerDetails, setOpenMdlTriggerDetails] = useState(false);
    const [integrationTriggerID, setIntegrationTriggerID] = useState(null);
    const [integrationTriggerPONumber, setIntegrationTriggerPONumber] =
        useState(null);

    const component = ModalTriggers.name;

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/
    async function getIntegrationTriggers({
        varSearch = '',
        varOrderBy = 'created_on',
        varOrder = 'desc',
        varPageNum = 0,
        varPerPage = 10,
        varOffSet = 0,
    }) {
        if (!status) {
            return { continueLoading: true };
        }

        let api_url = '/api/v1/integration-triggers?';
        api_url += `status=${status}&search=${varSearch}&pageNum=${varPageNum}&perPage=${varPerPage}&sortBy=${varOrderBy}&order=${varOrder}&offSet=${varOffSet}}&timeRange=${timeRange}`;

        API.PendingRequestsManager.registerRequest(
            component,
            'getAllIntegrationTriggers',
            api_url
        );

        try {
            const res = await API.get(api_url);
            return res;
        } catch (error) {
            return { error: true };
        }
    }
    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function closeMdlTriggerDetails() {
        setOpenMdlTriggerDetails(false);
        setIntegrationTriggerID(null);
        setIntegrationTriggerPONumber(null);
    }

    function openIntegrationTriggerByID(id, data) {
        setIntegrationTriggerID(id);
        setIntegrationTriggerPONumber(data?.poNumber);
        setOpenMdlTriggerDetails(true);
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/

    return (
        <Modal
            title={title}
            openModal={openModal}
            closeModal={closeModal}
            width="lg"
            contentNoPadding
            dialogActions={
                <>
                    <Button onClick={closeModal}>Close</Button>
                </>
            }
        >
            <TableContainer component={Card}>
                <Box sx={{ display: 'block' }}>
                    <DataTable
                        headCells={[
                            {
                                key: 'poNumber',
                                orderBy: 'po_number',
                                label: 'PO Number',
                            },
                            {
                                key: 'orderNumber',
                                orderBy: 'order_number',
                                label: 'Order Number',
                            },
                            {
                                key: 'locationNumber',
                                orderBy: 'location_number',
                                label: 'Location Number',
                            },
                            {
                                key: 'locationName',
                                orderBy: 'name',
                                label: 'Location Name',
                            },
                            {
                                key: 'status',
                                orderBy: 'status',
                                label: 'Status',
                            },
                            {
                                key: 'receivedAt',
                                orderBy: 'received_at',
                                label: 'Received On',
                                isDate: true,
                                format: 'MM/DD/YYYY hh:mm A',
                            },
                        ]}
                        primaryKey={'integrationTriggerID'}
                        tableCallBack={getIntegrationTriggers}
                        sendRowData={true}
                        rowCallBack={openIntegrationTriggerByID}
                        defaultOrder={'desc'}
                        defaultOrderBy={'received_at'}
                        defaultPerPage={10}
                        toolbar={{
                            searchField: true,
                        }}
                    />
                </Box>
            </TableContainer>

            {/***** LIGHTBOX: VIEW Trigger ********************************************************/}
            <ModalTrigger
                openModal={openMdlTriggerDetails}
                closeModal={closeMdlTriggerDetails}
                integrationTriggerID={integrationTriggerID}
                poNumber={integrationTriggerPONumber}
            />
            {/***** END MODAL: VIEW Trigger  ****************************************************/}
        </Modal>
    );
}

export default ModalTriggers;
