import React from 'react';
import { Box } from '@mui/material';

function PageContentHeader(props) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* RENDER APP
    -------------------------------------------------------------------------------------*/

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                pb: '16px',
                flexWrap: 'wrap',
            }}
        >
            {props.children}
        </Box>
    );
}

export default PageContentHeader;
