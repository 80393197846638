import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontFamily: ['trade-gothic-next', 'sans-serif'].join(','),
        h1: {
            fontSize: '36px',
            fontFamily: 'trade-gothic-next-condensed',
            fontWeight: '700',
            color: '#1c5787',
        },
        h2: {
            fontSize: '30px',
            fontFamily: 'trade-gothic-next-condensed',
            fontWeight: '700',
            color: '#1c5787',
        },
        h3: {
            fontSize: '24px',
            fontFamily: 'trade-gothic-next-condensed',
            fontWeight: '700',
            color: '#1c5787',
        },
        h4: {
            fontSize: '20px',
            fontFamily: 'trade-gothic-next-condensed',
            fontWeight: '700',
            color: '#1c5787',
        },
        h5: {
            fontSize: '16px',
            fontFamily: 'trade-gothic-next-condensed',
            fontWeight: '700',
            color: '#1c5787',
        },
        h6: {
            fontSize: '14px',
            fontFamily: 'trade-gothic-next-condensed',
            fontWeight: '700',
            color: '#1c5787',
            letterSpacing: '0.5px',
        },
        body1: {
            fontSize: '14px',
            color: '#555555',
        },
        body2: {
            fontSize: '12px',
            color: '#555555',
        },
    },

    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: 'black',
                    backgroundColor: 'white',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    paddingTop: '18px',
                    paddingBottom: '18px',
                    color: '#1c5787',
                    fontSize: '13px',
                    whiteSpace: 'nowrap',
                },
                body: {
                    fontSize: '13px',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#5c6b73',
            light: '#9db4c0',
            dark: '#253237',
        },
        secondary: {
            main: '#bd2531',
            light: '#f65c5b',
            dark: '#85000b',
        },
        text: {
            primary: '#555555',
            secondary: '#999999',
            disabled: 'rgba(0,0,0,0.38)',
        },
    },
});
