import React from 'react';
import Controls from '../../../Controls/Controls';
import { Box } from '@mui/material';

function AdditionalColumns({ options = [], varOption = [], setOption }) {
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        const allValues = value.map((v) => v.value);
        const resetValues = [...new Set(allValues)];
        let uniqueValues = null;
        resetValues.forEach((i) => {
            if (allValues.filter((a) => a === i).length === 1) {
                if (!uniqueValues) {
                    uniqueValues = value.filter((a) => a.value === i);
                } else {
                    uniqueValues.push(value.filter((a) => a.value === i)[0]);
                }
            }
        });
        setOption(uniqueValues || []);
    };

    return (
        <Box
            className="Additional-column-tool"
            sx={{ minWidth: '170px', ml: '8px' }}
        >
            <Controls.MultiSelect
                name="additional_columns"
                label="Additional Columns"
                sx={{ backgroundColor: '#ffffff', borderRadius: '4px' }}
                onChange={handleChange}
                value={varOption}
                options={options}
            />
        </Box>
    );
}

export default AdditionalColumns;
