import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';

function Spinner() {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {}, []);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Box
            sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                backdropFilter: 'blur(4px)',
                position: 'absolute',
                top: '0',
                right: '0',
                bottom: '0',
                left: '0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: '111',
            }}
        >
            <CircularProgress />
        </Box>
    );
}

export default Spinner;
