import React from 'react';
import { Menu, MenuItem, IconButton, Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LinkIcon from '@mui/icons-material/Link';

function MobileMenu({
    anchorEl,
    handleMenuOpen,
    handleMobileMenuClose,
    handleUserMenuOpen,
    handleNotificationMenuOpen,
}) {
    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={handleMenuOpen}
                onClose={handleMobileMenuClose}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    component="a"
                    href="https://mylogbook.spraguepest.com"
                    target="_blank"
                    onClick={handleMobileMenuClose}
                >
                    <IconButton size="small" color="inherit">
                        <LinkIcon />
                    </IconButton>
                    <p>Logbook</p>
                </MenuItem>
                <MenuItem
                    component="a"
                    href="https://mylogbook.spraguepest.com"
                    target="_blank"
                    onClick={handleMobileMenuClose}
                >
                    <IconButton size="small" color="inherit">
                        <LinkIcon />
                    </IconButton>
                    <p>Service Binder</p>
                </MenuItem>
                <MenuItem onClick={handleNotificationMenuOpen}>
                    <IconButton size="small" color="inherit">
                        <Badge badgeContent={11} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <p>Notifications</p>
                </MenuItem>
                <MenuItem onClick={handleUserMenuOpen}>
                    <IconButton size="small" color="inherit">
                        <AccountCircleIcon />
                    </IconButton>
                    <p>Profile</p>
                </MenuItem>
            </Menu>
        </>
    );
}

export default MobileMenu;
