import React from 'react';
import Controls from '../../../Controls/Controls';
import { Box } from '@mui/material';

function TableSingleStatusFilter({
    label,
    options = [],
    varOption,
    setOption,
}) {
    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Box
            className="Table-SB-Status-Filter-tool"
            sx={{
                minWidth: '170px',
                ml: { sm: '8px' },
                mt: { xs: '8px', sm: '0' },
                flexGrow: { xs: '1', sm: '0' },
            }}
        >
            <Controls.Select
                name="role"
                label={label}
                sx={{ backgroundColor: '#ffffff', borderRadius: '4px' }}
                onChange={(event) => setOption(event.target.value)}
                value={(options.length && varOption) || ''}
                options={options}
            />
        </Box>
    );
}

export default TableSingleStatusFilter;
