import React, {
    useState,
    createContext,
    useContext,
    useEffect,
    useRef,
} from 'react';
import API from '../libs/ServerAPIs';

const AppContext = createContext();

const ContextProvider = ({ children }) => {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const [var_visibleFrame, set_visibleFrame] = useState(false);
    const [var_drawer_open, set_drawer_open] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isActiveSession, setIsActiveSession] = useState(false);
    const [isLoadingData, set_loading_data] = useState(false);
    const [var_refresh_data, set_refresh_data] = useState(false);
    const [user_details, setUserDetails] = useState(null);
    const [isMobile, set_isMobile] = useState(window.innerWidth <= 768);
    const [app_top_bar_title, set_app_top_bar_title] = useState('');

    const prevNotificationRef = useRef();

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {}, [prevNotificationRef.current]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    const handleWindowSizeChange = () => {
        if (window.innerWidth <= 768) {
            set_isMobile(true);
        } else {
            set_isMobile(false);
        }
    };

    async function checkAuthSession() {
        const userInfo = await API.get('/api/v1/auth');
        return userInfo;
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    /* ContextProvider value
    -------------------------------------------------------------------------------------*/
    const data = {
        auth: [isAuth, setIsAuth],
        frame: [var_visibleFrame, set_visibleFrame],
        drawerOpen: [var_drawer_open, set_drawer_open],
        userData: [user_details, setUserDetails],
        refresh_data: [var_refresh_data, set_refresh_data],
        loading_data: [isLoadingData, set_loading_data],
        mobileCheck: [isMobile, set_isMobile],
        checkAuth: checkAuthSession,
        appTopBarTitleAsPerPage: [app_top_bar_title, set_app_top_bar_title],
        activeSession: [isActiveSession, setIsActiveSession],
    };

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
};

const useData = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useData can only be used inside AppContext');
    }
    return context;
};

export { ContextProvider, useData };
