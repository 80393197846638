import React, { lazy } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import Triggers from '../Pages/Triggers/triggers';

const Login = lazy(() => import('../Pages/Login/login'));

const Dashboard = lazy(() => import('../Pages/Dashboard/dashboard'));
const ManageUsers = lazy(() => import('../Pages/ManageUsers/manage-users'));
const Integrations = lazy(() => import('../Pages/Integrations/integrations'));
const Settings = lazy(() => import('../Pages/Settings/settings'));
const Reports = lazy(() => import('../Pages/Reports/reports'));
const NotFound = lazy(() => import('../Pages/NotFound/not-found'));

export function RenderRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path={'app'} element={<Outlet />}>
                <Route
                    path={''}
                    end
                    key={'Dashboard'}
                    element={<Dashboard />}
                />
                <Route
                    path={'manage-users'}
                    end
                    key={'ManageUsers'}
                    element={<ManageUsers />}
                />
                <Route
                    path={'integrations'}
                    end
                    key={'Integrations'}
                    element={<Integrations />}
                />
                <Route
                    path={'triggers'}
                    end
                    key={'Triggers'}
                    element={<Triggers />}
                />
                <Route
                    path={'settings'}
                    end
                    key={'Settings'}
                    element={<Settings />}
                />
                <Route
                    path={'reports'}
                    end
                    key={'Reports'}
                    element={<Reports />}
                />
                <Route path={'*'} element={<NotFound />} />
            </Route>
        </Routes>
    );
}
