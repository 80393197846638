import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import AssessmentIcon from '@mui/icons-material/Assessment';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SchemeIcon from '@mui/icons-material/Schema';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    StyledList,
    StyledLink,
    StyledIcon,
    StyledText,
    StyledListItem,
} from './styles';

function DrawerList() {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {}, []);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <>
            <StyledList component="nav">
                <StyledListItem>
                    <StyledLink component={NavLink} to="/app" end>
                        <StyledIcon>
                            <DashboardIcon />
                        </StyledIcon>
                        <StyledText disableTypography>Dashboard</StyledText>
                    </StyledLink>
                </StyledListItem>
                <StyledListItem>
                    <StyledLink component={NavLink} to="/app/manage-users" end>
                        <StyledIcon>
                            <PeopleOutlineIcon />
                        </StyledIcon>
                        <StyledText disableTypography>Manage Users</StyledText>
                    </StyledLink>
                </StyledListItem>
                <StyledListItem>
                    <StyledLink component={NavLink} to="/app/integrations" end>
                        <StyledIcon>
                            <SchemeIcon />
                        </StyledIcon>
                        <StyledText disableTypography>Integrations</StyledText>
                    </StyledLink>
                </StyledListItem>
                <StyledListItem>
                    <StyledLink component={NavLink} to="/app/triggers" end>
                        <StyledIcon>
                            <PodcastsIcon />
                        </StyledIcon>
                        <StyledText disableTypography>Triggers</StyledText>
                    </StyledLink>
                </StyledListItem>
                {/* <StyledListItem>
                    <StyledLink component={NavLink} to="/app/reports" end>
                        <StyledIcon>
                            <AssessmentIcon />
                        </StyledIcon>
                        <StyledText disableTypography>Reports</StyledText>
                    </StyledLink>
                </StyledListItem> */}
                <StyledListItem>
                    <StyledLink component={NavLink} to="/app/settings" end>
                        <StyledIcon>
                            <SettingsIcon />
                        </StyledIcon>
                        <StyledText disableTypography>App Settings</StyledText>
                    </StyledLink>
                </StyledListItem>
            </StyledList>
        </>
    );
}

export default DrawerList;
