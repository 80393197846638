import React from 'react';
import { Box } from '@mui/material';

function PageContent(props) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* RENDER APP
    -------------------------------------------------------------------------------------*/

    return (
        <Box sx={{ pt: { xs: '16px', sm: '24px', md: '48px' }, pb: '32px' }}>
            {props.children}
        </Box>
    );
}

export default PageContent;
