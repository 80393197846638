import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LoadingButton from '@mui/lab/LoadingButton';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import API from '../../libs/ServerAPIs';
import { useData } from '../../Context/Context';
import moment from 'moment';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Typography, Button, Paper, Box } from '@mui/material';
import { getActivityListChunk } from '../../libs/activityLog';

function ModalNotifications({
    openModal,
    closeModal,
    notificationsUpdateFlag,
    data,
}) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const { userData } = useData(useData);
    const [user_details] = userData;
    const [var_btn_loading, set_btn_loading] = useState(false);
    const [userNotifications, setUserNotifications] = useState({
        page: 1,
        notificationsList: [],
        dataSize: null,
        loadedNotifications: 0,
    });
    const [pagesLoaded, setPagesLoaded] = useState(1);
    const component = ModalNotifications.name;

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        if (openModal) {
            user_details?.type !== 'demo'
                ? getUserNotifications(pagesLoaded)
                : getFakeNotifications(pagesLoaded);
        } else {
            setUserNotifications({
                ...userNotifications,
                page: 1,
                notificationsList: [],
            });
        }

        return () => {
            API.PendingRequestsManager.abortRequest(
                component,
                'getNotifications'
            );
        };
    }, [pagesLoaded, openModal]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/
    async function getUserNotifications(loadPage = 1) {
        set_btn_loading(true);
        // Fetch notifications from backend (serverSide)
        const userNotificationsList = await getNotifications(loadPage);
        // Get notified by local logging activity (e.g. page visit)
        const activityNotifications = getActivityListChunk((loadPage - 1) * 10);
        set_btn_loading(false);
        // If there are any notifications to show (i.e. either from front or backend) then merge these notifications in single list and put those on view
        if (
            (activityNotifications && activityNotifications.length > 0) ||
            (userNotificationsList?.data &&
                userNotificationsList?.data.length > 0)
        ) {
            let mergedNotificationsList = [];
            // Load More:
            // If there are notifications in the list then merge new notifications with the existing ones (and expand the list)
            // if (userNotifications.notificationsList.length > 0) {
            if (pagesLoaded > userNotifications.page) {
                mergedNotificationsList =
                    userNotifications.notificationsList.concat(
                        userNotificationsList?.data ?? []
                    );
            } // Otherwise only load initial notifications if the modal is just opened
            else {
                // Merge local activities and notifications from backend
                mergedNotificationsList = [
                    ...activityNotifications,
                    ...(userNotificationsList?.data ?? []),
                ];
            }
            // Arrange notifications in descending order by time
            mergedNotificationsList.sort(
                (first, second) => new Date(second.time) - new Date(first.time)
            );
            setUserNotifications({
                page: loadPage,
                //notificationsList: userNotifications.notificationsList.concat(mergedNotificationsList).sort((first, second) => second.time - first.time),
                notificationsList: mergedNotificationsList,
                dataSize: mergedNotificationsList.length,
                loadedNotifications: mergedNotificationsList.length,
            });
        }
    }

    async function getNotifications(pageNum = 1) {
        const requestUrl = `/api/v1/logger?pageNum=${pageNum}`;
        API.PendingRequestsManager.registerRequest(
            component,
            'getNotifications',
            requestUrl
        );
        const logger = await API.get(requestUrl);
        return logger;
    }

    function getFakeNotifications(loadPage = 1) {
        // console.log(`Getting fake notifications `);
        const generatedNotifications = JSON.parse(
            sessionStorage.getItem('fakeNotifications')
        )['fakeNotifications'];
        const mergedNotificationsList =
            userNotifications.notificationsList.concat(
                generatedNotifications?.data ?? []
            );
        mergedNotificationsList.sort(
            (first, second) => new Date(second.time) - new Date(first.time)
        );
        setUserNotifications({
            page: loadPage,
            //notificationsList: userNotifications.notificationsList.concat(mergedNotificationsList).sort((first, second) => second.time - first.time),
            notificationsList: mergedNotificationsList,
            dataSize: mergedNotificationsList.length,
            loadedNotifications: mergedNotificationsList.length,
        });

        //console.log(`fakeNotifications `, generatedNotifications);
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    function loadMore() {
        setPagesLoaded(pagesLoaded + 1);
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/

    return (
        <Modal
            title={
                <>
                    <NotificationsIcon /> {' Notifications'}
                </>
            }
            openModal={openModal}
            closeModal={closeModal}
            width="sm"
            contentNoPadding
            dialogActions={
                <>
                    <Button onClick={() => getUserNotifications(1)}>
                        Update
                    </Button>
                    <Button onClick={closeModal}>Close</Button>
                </>
            }
        >
            <Box sx={{ backgroundColor: '#f4f6f8', p: '24px 24px 16px' }}>
                {userNotifications.notificationsList.map(
                    (userNotification, key) => (
                        <Paper key={key} square sx={{ mb: '8px' }}>
                            <Box
                                sx={{
                                    padding: '16px',
                                    borderLeft: '3px solid',
                                    borderColor: 'secondary.main',
                                }}
                            >
                                <Box sx={{}}>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                            mr: '4px',
                                        }}
                                    >
                                        {userNotification.entity}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        {' '}
                                        "{userNotification.description}"
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mt: '24px',
                                    }}
                                >
                                    {user_details &&
                                        userNotification.user &&
                                        (user_details.role ===
                                            'Customer Admin' ||
                                            user_details.role ===
                                                'Super Admin' ||
                                            user_details.role ===
                                                'Content Manager' ||
                                            user_details.role === 'Admin') && (
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: 'text.secondary',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <PersonOutlineOutlinedIcon
                                                    sx={{
                                                        mr: '4px',
                                                        fontSize: '20px',
                                                    }}
                                                />
                                                <Box>
                                                    {userNotification.user}
                                                </Box>
                                            </Typography>
                                        )}
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: 'text.secondary',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <AccessTimeIcon
                                            sx={{ mr: '4px', fontSize: '20px' }}
                                        />
                                        <Box>
                                            {moment(
                                                userNotification.time
                                            ).format('l - LT')}
                                        </Box>
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                    )
                )}
                <Box sx={{ textAlign: 'center', py: '24px' }}>
                    {userNotifications.dataSize !== 0 &&
                        user_details &&
                        user_details?.type !== 'demo' && (
                            <LoadingButton
                                variant="outlined"
                                loading={var_btn_loading}
                                endIcon={<DoubleArrowIcon />}
                                loadingPosition="end"
                                onClick={loadMore}
                            >
                                Load More
                            </LoadingButton>
                        )}
                </Box>
            </Box>
        </Modal>
    );
}

export default ModalNotifications;
