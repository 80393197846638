import { styled } from '@mui/material/styles';
import {} from '@mui/material';

export const Input = styled('input')({
    display: 'none',
});

export const Label = styled('label')({
    width: '100%',
});
