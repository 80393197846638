import Input from './Input';
import RadioGroup from './RadioGroup';
import Select from './Select';
import Checkbox from './Checkbox';
import BasicDatePicker from './BasicDatePicker';
import MultiSelect from './MultiSelect';
import MultiSelectGroupBy from './MultiSelectGroupBy';
import MultiSelectPagination from './MultiSelectPagination';
import CustomizableMultiSelect from './CustomizableMultiSelect';
import File from './File';

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    BasicDatePicker,
    MultiSelect,
    File,
    MultiSelectGroupBy,
    CustomizableMultiSelect,
    MultiSelectPagination,
};

export default Controls;
