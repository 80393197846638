import React from 'react';
import { Box } from '@mui/system';
import Moment from 'react-moment';
import { useData } from '../../../Context/Context';

function LastLoggedIn({ userMenu = false }) {
    const { userData } = useData(useData);
    const [user_details] = userData;
    return (
        <Box
            sx={{
                color: 'rgba(255, 255, 255, 0.75)',
                textAlign: 'right',
                display: 'flex',
                alignItems: userMenu ? 'flex-start' : 'flex-end',
                px: !userMenu ? '4px' : '0',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Box>Last login</Box>

            <Box sx={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
                <Box>
                    {' '}
                    {user_details?.lastLogin === 'No Login Details' ? (
                        user_details?.lastLogin
                    ) : (
                        <Moment format="MMM D YYYY hh:mm A">
                            {user_details?.lastLogin}
                        </Moment>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default LastLoggedIn;
