import React, { useState, useEffect, useRef } from 'react';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SyncIcon from '@mui/icons-material/Sync';
import EmptyState from '../EmptyState';
import MoreMenu from '../MoreMenu';
import Search from './Components/Search';
import FilterTimeRangeDropdown from '../FilterTimeRangeDropdown';
import AdditionalColumns from './Components/AdditionalColumns';
import CategoryFilter from './Components/CategoryFilter';
import TableSingleStatusFilter from './Components/TableSingleStatusFilter';
import Moment from 'react-moment';
import LoadMore from './Components/LoadMore';
import { useData } from '../../Context/Context';
import API from '../../libs/ServerAPIs';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import RoofingIcon from '@mui/icons-material/Roofing';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Chip,
    Paper,
    Table,
    Button,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Checkbox,
    IconButton,
    Tooltip,
    Typography,
    TablePagination,
    Switch,
} from '@mui/material';
import moment from 'moment';
import MomentTZ from 'moment-timezone';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function ChangeLogActivity({ activityString }) {
    const [var_only_string, set_only_string] = useState('');
    const [var_split_string, set_split_string] = useState([]);
    var match = /\r|\n/.exec(activityString);

    useEffect(() => {
        if (!match) {
            set_only_string(activityString);
        }
        if (match) {
            set_split_string(
                activityString.split('\n').filter((element) => element)
            );
        }
    }, []);

    return (
        <>
            <Box>{var_only_string}</Box>
            {(var_split_string.length > 0 || activityString === '') && (
                <Box>
                    <Box>Updated</Box>
                    {var_split_string.length > 0 && (
                        <Box>
                            {var_split_string.map((row) => {
                                let updateFileName = null;
                                let updateFilePath = null;
                                let updateDates = null;

                                if (row.includes('UpdateRequiredBy')) {
                                    let UpdateRequiredBy = row
                                        .split('=')
                                        .filter((elem) => elem.includes('>'));
                                    updateDates =
                                        UpdateRequiredBy.toString().split('>');
                                }

                                if (row.includes('FilePath')) {
                                    let UpdateByFilePath = row
                                        .split('=')
                                        .filter((elem) => elem.includes('>'));
                                    updateFilePath =
                                        UpdateByFilePath.toString().split('>');
                                }

                                if (row.includes('FileName')) {
                                    let UpdateByFileName = row
                                        .split('=')
                                        .filter((elem) => elem.includes('>'));
                                    updateFileName =
                                        UpdateByFileName.toString().split('>');
                                    // console.log('updateFileName>>>>>>>>>', updateFileName)
                                }

                                return (
                                    <>
                                        {updateFileName && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    mt: '8px',
                                                }}
                                            >
                                                <Box sx={{ fontWeight: '500' }}>
                                                    FileName
                                                </Box>
                                                <Box
                                                    sx={{
                                                        ml: '24px',
                                                        textDecoration:
                                                            'line-through',
                                                    }}
                                                >
                                                    {updateFileName[0]}
                                                </Box>
                                                <Box sx={{ ml: '24px' }}>
                                                    {updateFileName[1]}
                                                </Box>
                                            </Box>
                                        )}
                                        {updateFilePath && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    mt: '8px',
                                                }}
                                            >
                                                <Box sx={{ fontWeight: '500' }}>
                                                    FilePath
                                                </Box>
                                                <Box
                                                    sx={{
                                                        ml: '24px',
                                                        textDecoration:
                                                            'line-through',
                                                    }}
                                                >
                                                    {updateFilePath[0]}
                                                </Box>
                                                <Box sx={{ ml: '24px' }}>
                                                    {updateFilePath[1]}
                                                </Box>
                                            </Box>
                                        )}
                                        {updateDates && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    mt: '8px',
                                                }}
                                            >
                                                <Box sx={{ fontWeight: '500' }}>
                                                    UpdateRequiredBy
                                                </Box>
                                                <Box
                                                    sx={{
                                                        ml: '24px',
                                                        textDecoration:
                                                            'line-through',
                                                    }}
                                                >
                                                    {updateDates[0]}
                                                </Box>
                                                <Box sx={{ ml: '24px' }}>
                                                    {updateDates[1]}
                                                </Box>
                                            </Box>
                                        )}
                                    </>
                                );
                            })}
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
}

function EnhancedTableHead({
    headCells,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    isCheckboxSelection,
    hasMoreMenu,
    hasActions,
    isSelectedAllDisabled,
}) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {isCheckboxSelection && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={
                                numSelected > 0 && numSelected < rowCount
                            }
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{}}
                            disabled={isSelectedAllDisabled}
                        />
                    </TableCell>
                )}

                {headCells.map((headCell, index) =>
                    headCell.label && headCell.orderBy !== null ? (
                        <TableCell
                            key={`head-${index}${headCell.key}`}
                            align={
                                headCell.isNumeric
                                    ? 'right'
                                    : `${
                                          headCell.align
                                              ? headCell.align
                                              : 'left'
                                      }`
                            }
                            padding={
                                headCell.disablePadding ? 'none' : 'normal'
                            }
                            sortDirection={
                                orderBy === headCell.orderBy ? order : false
                            }
                        >
                            <TableSortLabel
                                active={orderBy === headCell.orderBy}
                                direction={
                                    orderBy === headCell.orderBy ? order : 'asc'
                                }
                                onClick={createSortHandler(headCell.orderBy)}
                            >
                                {headCell.label}
                                {orderBy === headCell.orderBy ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ) : headCell.label && headCell.orderBy === null ? (
                        <TableCell
                            key={`head-${index}${headCell.key}`}
                            align={
                                headCell.isNumeric
                                    ? 'right'
                                    : `${
                                          headCell.align
                                              ? headCell.align
                                              : 'left'
                                      }`
                            }
                            padding={
                                headCell.disablePadding ? 'none' : 'normal'
                            }
                        >
                            {headCell.label}
                        </TableCell>
                    ) : (
                        <TableCell
                            key={`head-${index}${headCell.key}`}
                            sx={{ width: '40px', pr: '0' }}
                        ></TableCell>
                    )
                )}
                {hasMoreMenu && <TableCell></TableCell>}
                {hasActions && <TableCell align="right">Action</TableCell>}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = ({
    numSelected,
    handleSearchChange,
    searchValue,
    loading,
    isFilter,
    isReSync,
    isSearch,
    isFilterTimeRange,
    isAdditionalColumns,
    varTimeRangeFilter,
    setTimeRangeFilter,
    isCategoryFilter,
    isTableSingleStatusFilter,
    toolbarCustomActions,
    checkboxSelectionActions,
    reSyncLabel,
    reSyncLoading,
    syncDate,
    reSyncOnClick,
}) => {
    return (
        <Toolbar
            sx={{
                minHeight: '0 !important',
                p: '16px !important',
                backgroundColor: '#EAEEF3',
                borderRadius: '4px 4px 0 0',
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        minHeight: '53px',
                        boxSizing: 'border-box',
                    }}
                >
                    <Box>
                        <Typography
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            {numSelected} selected
                        </Typography>
                    </Box>
                    <Box sx={{ flex: '1' }}></Box>
                    <Box>
                        {checkboxSelectionActions && (
                            <>{checkboxSelectionActions}</>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: { sm: 'flex' },
                        flex: '1',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    <Box className="Toolbar-left">
                        {isSearch && (
                            <Search
                                value={searchValue}
                                handleSearchChange={handleSearchChange}
                            />
                        )}
                    </Box>
                    <Box
                        className="Toolbar-right"
                        sx={{
                            display: 'flex',
                            ml: { sm: 'auto' },
                            flexWrap: 'wrap',
                        }}
                    >
                        {isCategoryFilter.isCategoryFilter && (
                            <CategoryFilter
                                options={isCategoryFilter.options}
                                varOption={isCategoryFilter.varOption}
                                setOption={isCategoryFilter.setOption}
                            />
                        )}
                        {isTableSingleStatusFilter.isTableSingleStatusFilter && (
                            <TableSingleStatusFilter
                                label={
                                    isTableSingleStatusFilter
                                        .isTableSingleStatusFilter.label
                                }
                                options={isTableSingleStatusFilter.options}
                                varOption={isTableSingleStatusFilter.varOption}
                                setOption={isTableSingleStatusFilter.setOption}
                            />
                        )}
                        {isAdditionalColumns.isAdditionalColumns && (
                            <AdditionalColumns
                                options={isAdditionalColumns.options}
                                varOption={isAdditionalColumns.varOption}
                                setOption={isAdditionalColumns.setOption}
                            />
                        )}
                        {isFilterTimeRange && (
                            <Box className="TimeRange-container">
                                <FilterTimeRangeDropdown
                                    var_timeRange={varTimeRangeFilter}
                                    setFilterValue={setTimeRangeFilter}
                                    loading={loading}
                                    pastLimitMonths={60}
                                />
                            </Box>
                        )}
                        {isFilter && (
                            <Tooltip title="Filter list" arrow>
                                <IconButton>
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isReSync && (
                            <LoadingButton
                                startIcon={<SyncIcon />}
                                loadingPosition="start"
                                disabled={loading || reSyncLoading}
                                loading={loading || reSyncLoading}
                                loadingIndicator={
                                    <SyncIcon
                                        sx={{
                                            animation:
                                                'spin 2s linear infinite',
                                            '@keyframes spin': {
                                                '0%': {
                                                    transform: 'rotate(360deg)',
                                                },
                                                '100%': {
                                                    transform: 'rotate(0deg)',
                                                },
                                            },
                                        }}
                                    />
                                }
                                onClick={() => {
                                    if (
                                        reSyncOnClick &&
                                        typeof reSyncOnClick === 'function'
                                    ) {
                                        return reSyncOnClick();
                                    }
                                    return false;
                                }}
                                sx={{
                                    textAlign: 'left',
                                    textTransform: 'none',
                                    ml: '8px',
                                }}
                            >
                                <Box
                                    sx={{ fontSize: '12px', lineHeight: '1.1' }}
                                >
                                    <Box>{reSyncLabel || 'Re-Sync'}</Box>
                                    <Tooltip
                                        title={`Updated on: ${
                                            syncDate &&
                                            moment
                                                .utc(syncDate)
                                                .local()
                                                .format('MM/DD/YYYY hh:mm A z')
                                        } ${MomentTZ.tz(
                                            MomentTZ.tz.guess()
                                        ).zoneName()}`}
                                        arrow
                                    >
                                        <Box>
                                            {syncDate &&
                                                moment
                                                    .utc(syncDate)
                                                    .local()
                                                    .format(
                                                        'MM/DD/YYYY hh:mm A z'
                                                    )}{' '}
                                            {MomentTZ.tz(
                                                MomentTZ.tz.guess()
                                            ).zoneName()}
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </LoadingButton>
                        )}
                        {toolbarCustomActions && <>{toolbarCustomActions}</>}
                    </Box>
                </Box>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

function DataTable(props) {
    const {
        headCells = [],
        primaryKey,
        tableCallBack,
        rowCallBack,
        sendRowData = false,
        defaultOrder = 'asc',
        defaultOrderBy = '',
        defaultPerPage = 5,
        cellActions,
        isCheckboxSelection = false,
        toolbar,
        toolbarCustomActions,
        isPagination = false,
        moreMenu,
        onUpdate = false,
        selected = [],
        setSelected = () => {},
        selectedKey = '',
        selectedKeyValue = '',
        rowDisabledKey = null,
        isRowDisabled = false,
        isSelectedAllDisabled = false,
        parentComponent,
        checkboxSelectionActions,
        timeRangeFilterDefaultValue,
    } = props;

    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const { userData } = useData(useData);
    const [user_details] = userData;
    const [order, setOrder] = useState(defaultOrder || '');
    const [orderBy, setOrderBy] = useState(defaultOrderBy || '');
    const [pageNum, setPageNum] = useState(0);
    const [perPage, setPerPage] = useState(defaultPerPage);
    const [offSet, setOffSet] = useState(0);
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [timeRangeFilter, setTimeRangeFilter] = useState(
        timeRangeFilterDefaultValue || '&timeRange=30_days'
    );
    const [tableSingleStatusFilter, setTableSingleStatusFilter] = useState(
        toolbar?.isTableSingleStatusFilter?.varOption || 'ACTIVE'
    );
    const [category, setCategory] = useState('All');
    const [isMobile, set_isMobile] = useState(window.innerWidth <= 768);
    const [rowDisabledOn, setRowDisabledOn] = useState(null);
    const prevCategory = usePrevious(category);
    const prevSearch = usePrevious(search);
    const prevTimeRangeFilter = usePrevious(timeRangeFilter);
    const prevTableSingleStatusFilter = usePrevious(tableSingleStatusFilter);
    const prevPerPage = usePrevious(perPage);
    const prevOnUpdate = usePrevious(onUpdate);

    const component = DataTable.name;
    // const blue500 = blue['500'];

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        setOffSet(Math.max(offSet, data.length));
        if (data.length) {
            if (isCheckboxSelection && selectedKey) {
                if (selectedKeyValue) {
                    const dataSelected = data.filter(
                        (n) => n[selectedKey] === selectedKeyValue
                    );
                    const selectedKeys = dataSelected.map((n) => n[primaryKey]);
                    const mergeSelectedKey = [...selected, ...selectedKeys];
                    setSelected([...new Set(mergeSelectedKey)]);
                } else {
                    const dataSelected = data.filter(
                        (n) => n[selectedKey] === true
                    );
                    const selectedKeys = dataSelected.map((n) => n[primaryKey]);
                    const mergeSelectedKey = [...selected, ...selectedKeys];
                    setSelected([...new Set(mergeSelectedKey)]);
                }
            }
            setLoading(false);
        }
    }, [data]);

    useEffect(() => {
        if (prevTimeRangeFilter !== timeRangeFilter) {
            const timer = setTimeout(() => {
                callTableFunction({ varPageNum: 0, varOffSet: 0 });
            }, 300);

            return () => clearTimeout(timer);
        } else if (prevSearch !== search) {
            const timer = setTimeout(() => {
                if (search !== null) {
                    callTableFunction({ varPageNum: 0, varOffSet: 0 });
                }
            }, 300);

            return () => clearTimeout(timer);
        } else if (prevPerPage !== perPage) {
            callTableFunction({ varPerPage: perPage });
        }
    }, [search, timeRangeFilter, perPage]);

    useEffect(() => {
        if (typeof prevOnUpdate !== 'undefined' && prevOnUpdate !== onUpdate) {
            callTableFunction({
                varPageNum: 0,
                varOffSet: 0,
                varSearch: search,
            });
        } else if (
            (typeof prevCategory !== 'undefined' &&
                prevCategory !== category) ||
            (typeof prevTableSingleStatusFilter !== 'undefined' &&
                prevOnUpdate !== tableSingleStatusFilter)
        ) {
            callTableFunction({ varPageNum: 0, varOffSet: 0 });
        }
    }, [onUpdate, category, tableSingleStatusFilter]);

    useEffect(() => {
        if (selected.length && isRowDisabled && rowDisabledKey) {
            const dataSelected = data.filter(
                (n) => n[primaryKey] === selected[0]
            );
            setRowDisabledOn(dataSelected[0][rowDisabledKey]);
        }
        if (!selected.length) {
            setRowDisabledOn(null);
        }
    }, [selected]);

    useEffect(() => {
        return () => {
            if (
                tableCallBack &&
                typeof tableCallBack === 'function' &&
                parentComponent
            ) {
                API.PendingRequestsManager.abortRequest(
                    parentComponent,
                    tableCallBack.name
                );
            }
            API.PendingRequestsManager.abortRequest(
                component,
                'tableCallBack',
                ''
            );
        };
    }, []);

    const handleWindowSizeChange = () => {
        if (window.innerWidth <= 768) {
            set_isMobile(true);
        } else {
            set_isMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/
    const callTableFunction = async ({
        varCategory = category,
        varTableSingleStatusFilter = tableSingleStatusFilter,
        varSearch = search,
        varPerPage = perPage,
        varTimeRangeFilter = timeRangeFilter,
        varOrder,
        varOrderBy,
        varPageNum,
        varOffSet,
    }) => {
        if (varOffSet === 0) {
            setData([]);
        }
        setLoading(true);
        varCategory = varCategory || category;
        varTableSingleStatusFilter =
            varTableSingleStatusFilter || tableSingleStatusFilter;
        varSearch = varSearch || search;
        varPerPage = varPerPage || perPage;
        varTimeRangeFilter = toolbar?.timeRange
            ? varTimeRangeFilter || timeRangeFilter
            : '';
        if (varOrder && varOrder !== order) {
            setOrder(varOrder);
        } else {
            varOrder = order;
        }
        if (varOrderBy && varOrderBy !== orderBy) {
            setOrderBy(varOrderBy);
        } else {
            varOrderBy = orderBy;
        }
        if ((varPageNum || varPageNum === 0) && varPageNum !== pageNum) {
            setPageNum(varPageNum);
        } else {
            varPageNum = pageNum;
        }
        if ((varOffSet || varOffSet === 0) && varOffSet !== offSet) {
            setOffSet(varOffSet);
        } else {
            varOffSet = offSet;
        }
        API.PendingRequestsManager.registerRequest(
            component,
            'tableCallBack',
            ''
        );
        const res = await tableCallBack({
            varCategory,
            varTableSingleStatusFilter,
            varSearch,
            varOrder,
            varOrderBy,
            varPageNum,
            varPerPage,
            varTimeRangeFilter,
            varOffSet,
        });
        if (!res.error && !res.continueLoading) {
            setData(
                varOffSet && res.data.length ? [...data, ...res.data] : res.data
            );
            if (res?.pagination?.total) {
                setTotal(res.pagination.total);
            }
            if (!res.data.length) {
                setLoading(false);
            }
        }
        /* begin: temp addition */
        if (res.continueLoading) {
            return true;
        }
        /* end: temp addition */
        if (res.error) {
            setData([]);
            setLoading(false);
        }
    };

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        callTableFunction({
            varOrder: isAsc ? 'desc' : 'asc',
            varOrderBy: property,
            varPageNum: 0,
            varOffSet: 0,
        });
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n[primaryKey]);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (key, row) => {
        if (rowCallBack && typeof rowCallBack === 'function') {
            if (sendRowData) {
                return rowCallBack(key, row);
            }
            return rowCallBack(key);
        }
    };

    const handleMoreMenuClick = (key, rowData, actionName) => {
        let moreMenuFunction = () => {
            return true;
        };
        switch (actionName) {
            case 'View':
                if (moreMenu.onView && typeof moreMenu.onView === 'function') {
                    return moreMenu.onView(key, rowData);
                }
                return moreMenuFunction;
            case 'Edit':
                if (moreMenu.onEdit && typeof moreMenu.onEdit === 'function') {
                    return moreMenu.onEdit(key, rowData);
                }
                return moreMenuFunction;
            case 'Delete':
                if (
                    moreMenu.onDelete &&
                    typeof moreMenu.onDelete === 'function'
                ) {
                    return moreMenu.onDelete(key, rowData);
                }
                return moreMenuFunction;
            case 'Deactivate':
                if (
                    moreMenu.onDeactivate &&
                    typeof moreMenu.onDeactivate === 'function'
                ) {
                    return moreMenu.onDeactivate(key, rowData);
                }
                return moreMenuFunction;
            case 'RequestOrganic':
                if (
                    moreMenu.onOrganicRequest &&
                    typeof moreMenu.onOrganicRequest === 'function'
                ) {
                    return moreMenu.onOrganicRequest(key, rowData);
                }
                return moreMenuFunction;
            case 'AddUserInLocation':
                if (
                    moreMenu.onAddUser &&
                    typeof moreMenu.onAddUser === 'function'
                ) {
                    return moreMenu.onAddUser(key, rowData);
                }
                return moreMenuFunction;
            case 'AddLocationInUser':
                if (
                    moreMenu.onAddLocation &&
                    typeof moreMenu.onAddLocation === 'function'
                ) {
                    return moreMenu.onAddLocation(key, rowData);
                }
                return moreMenuFunction;
            case 'RemoveLocationInUser':
                if (
                    moreMenu.onRemoveLocation &&
                    typeof moreMenu.onRemoveLocation === 'function'
                ) {
                    return moreMenu.onRemoveLocation(key, rowData);
                }
                return moreMenuFunction;
            case 'ApproveOrRejectUser':
                if (
                    moreMenu.onApproveOrReject &&
                    typeof moreMenu.onApproveOrReject === 'function'
                ) {
                    return moreMenu.onApproveOrReject(key, rowData);
                }
                return moreMenuFunction;
            case 'ActiveUser':
                if (
                    moreMenu.onActivateUser &&
                    typeof moreMenu.onActivateUser === 'function'
                ) {
                    return moreMenu.onActivateUser(key, rowData);
                }
                return moreMenuFunction;
            case 'ApproveOrganicRequests':
                if (
                    moreMenu.onApprove &&
                    typeof moreMenu.onApprove === 'function'
                ) {
                    return moreMenu.onApprove(key, rowData);
                }
                return moreMenuFunction;
            case 'RejectOrganicRequests':
                if (
                    moreMenu.onReject &&
                    typeof moreMenu.onReject === 'function'
                ) {
                    return moreMenu.onReject(key, rowData);
                }
                return moreMenuFunction;
            case 'SendUserActivationLink':
                if (
                    moreMenu.onResendActivationLink &&
                    typeof moreMenu.onResendActivationLink === 'function'
                ) {
                    return moreMenu.onResendActivationLink(key, rowData);
                }
                return moreMenuFunction;
            case 'OverrideSDSandLabels':
                if (
                    moreMenu.onSDSandLabelsOverride &&
                    typeof moreMenu.onSDSandLabelsOverride === 'function'
                ) {
                    return moreMenu.onSDSandLabelsOverride(key, rowData);
                }
                return moreMenuFunction;
            case 'StopOverrideSDS':
                if (
                    moreMenu.onStopOverrideSDS &&
                    typeof moreMenu.onStopOverrideSDS === 'function'
                ) {
                    return moreMenu.onStopOverrideSDS(key, rowData);
                }
                return moreMenuFunction;
            case 'StopOverrideLabel':
                if (
                    moreMenu.onStopOverrideLabel &&
                    typeof moreMenu.onStopOverrideLabel === 'function'
                ) {
                    return moreMenu.onStopOverrideLabel(key, rowData);
                }
                return moreMenuFunction;
            case 'ChangeLog':
                if (
                    moreMenu.onChangelog &&
                    typeof moreMenu.onChangelog === 'function'
                ) {
                    return moreMenu.onChangelog(key, rowData);
                }
                return moreMenuFunction;
            case 'ClickLicense':
                if (
                    moreMenu.onClickLicense &&
                    typeof moreMenu.onClickLicense === 'function'
                ) {
                    return moreMenu.onClickLicense(key, rowData);
                }
                return moreMenuFunction;
            case 'UploadLicense':
                if (
                    moreMenu.onUploadLicense &&
                    typeof moreMenu.onUploadLicense === 'function'
                ) {
                    return moreMenu.onUploadLicense(key, rowData);
                }
                return moreMenuFunction;
            case 'CheckLicense':
                if (
                    moreMenu.onCheckLicense &&
                    typeof moreMenu.onCheckLicense === 'function'
                ) {
                    return moreMenu.onCheckLicense(key, rowData);
                }
                return moreMenuFunction;
            default:
                return moreMenuFunction;
        }
    };

    const handleChangeCheckbox = (event, name, rowData) => {
        if (
            isRowDisabled &&
            rowDisabledKey &&
            rowDisabledOn &&
            rowData[rowDisabledKey] !== rowDisabledOn
        ) {
            return false;
        }
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPageNum(newPage);
        callTableFunction({ varPageNum: newPage });
    };

    const handleChangeRowsPerPage = (event) => {
        setPageNum(0);
        setPerPage(parseInt(event.target.value, 10));
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        pageNum > 0 ? Math.max(0, (1 + pageNum) * perPage - data.length) : 0;

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const onClickLoadMore = () => {
        callTableFunction({ varPerPage: perPage, varPageNum: pageNum + 1 });
    };

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Paper>
            {toolbar && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    handleSearchChange={handleSearchChange}
                    searchValue={search}
                    isFilter={toolbar?.filterButton}
                    isReSync={toolbar?.reSyncButton}
                    reSyncLabel={toolbar?.reSyncButton?.label}
                    reSyncLoading={toolbar?.reSyncButton?.reSyncLoading}
                    syncDate={toolbar?.reSyncButton?.syncDate}
                    reSyncOnClick={toolbar?.reSyncButton?.onClick}
                    isSearch={toolbar?.searchField}
                    isFilterTimeRange={toolbar?.timeRange}
                    setTimeRangeFilter={setTimeRangeFilter}
                    varTimeRangeFilter={timeRangeFilterDefaultValue}
                    toolbarCustomActions={toolbarCustomActions}
                    checkboxSelectionActions={checkboxSelectionActions}
                    loading={loading}
                    isCategoryFilter={{
                        isCategoryFilter: toolbar.isCategoryFilter,
                        options:
                            toolbar.isCategoryFilter &&
                            toolbar.isCategoryFilter.options,
                        varOption: category,
                        setOption: setCategory,
                    }}
                    isTableSingleStatusFilter={{
                        isTableSingleStatusFilter:
                            toolbar.isTableSingleStatusFilter,
                        options:
                            toolbar.isTableSingleStatusFilter &&
                            toolbar.isTableSingleStatusFilter.options,
                        varOption: tableSingleStatusFilter,
                        setOption: setTableSingleStatusFilter,
                    }}
                    isAdditionalColumns={{
                        isAdditionalColumns: toolbar.isAdditionalColumns,
                        options:
                            (toolbar.isAdditionalColumns &&
                                toolbar.isAdditionalColumns.options) ||
                            [],
                        varOption:
                            (toolbar.isAdditionalColumns &&
                                toolbar.isAdditionalColumns.varOption) ||
                            [],
                        setOption:
                            (toolbar.isAdditionalColumns &&
                                toolbar.isAdditionalColumns.setOption) ||
                            null,
                    }}
                />
            )}

            <TableContainer>
                <Table>
                    <EnhancedTableHead
                        headCells={headCells.filter((hC) => hC.key)}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                        isCheckboxSelection={isCheckboxSelection}
                        hasMoreMenu={moreMenu}
                        hasActions={cellActions}
                        isSelectedAllDisabled={isSelectedAllDisabled}
                    />
                    <TableBody
                        sx={{
                            '& > tr:last-child > td': { borderBottom: 'none' },
                        }}
                    >
                        {data.map((row, index) => {
                            const isItemSelected = isSelected(row[primaryKey]);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={`row-${index}${row[primaryKey]}`}
                                    selected={isItemSelected}
                                    sx={{
                                        cursor:
                                            typeof rowCallBack === 'function'
                                                ? 'pointer'
                                                : 'auto',
                                        opacity:
                                            isRowDisabled &&
                                            rowDisabledKey &&
                                            rowDisabledOn &&
                                            row[rowDisabledKey] !==
                                                rowDisabledOn
                                                ? '0.5'
                                                : '1',
                                    }}
                                >
                                    {isCheckboxSelection && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                onClick={(event) =>
                                                    handleChangeCheckbox(
                                                        event,
                                                        row[primaryKey],
                                                        row
                                                    )
                                                }
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                    )}

                                    {headCells
                                        .filter((hC) => hC.key)
                                        .map((cell, index) => (
                                            <TableCell
                                                align={
                                                    cell.isNumeric
                                                        ? 'right'
                                                        : `${
                                                              cell.align
                                                                  ? cell.align
                                                                  : 'left'
                                                          }`
                                                }
                                                key={`cell-${index}${row[primaryKey]}`}
                                                onClick={() => {
                                                    if (
                                                        isRowDisabled &&
                                                        rowDisabledKey &&
                                                        rowDisabledOn &&
                                                        row[rowDisabledKey] !==
                                                            rowDisabledOn
                                                    ) {
                                                        return false;
                                                    }
                                                    if (
                                                        selected.length &&
                                                        isCheckboxSelection
                                                    ) {
                                                        return handleChangeCheckbox(
                                                            null,
                                                            row[primaryKey],
                                                            row
                                                        );
                                                    }
                                                    if (
                                                        (row[cell.key] !==
                                                            undefined ||
                                                            cell.sendNull) &&
                                                        cell.onClick
                                                    ) {
                                                        if (sendRowData) {
                                                            cell.onClick(
                                                                cell.onClickKey
                                                                    ? row[
                                                                          cell
                                                                              .onClickKey
                                                                      ]
                                                                    : row[
                                                                          primaryKey
                                                                      ],
                                                                row
                                                            );
                                                        } else {
                                                            cell.onClick(
                                                                cell.onClickKey
                                                                    ? row[
                                                                          cell
                                                                              .onClickKey
                                                                      ]
                                                                    : row[
                                                                          primaryKey
                                                                      ]
                                                            );
                                                        }
                                                    } else {
                                                        handleClick(
                                                            row[primaryKey],
                                                            row
                                                        );
                                                    }
                                                }}
                                                sx={{
                                                    cursor:
                                                        typeof cell.onClick ===
                                                        'function'
                                                            ? 'pointer'
                                                            : 'auto',
                                                }}
                                            >
                                                {cell.isToggle && (
                                                    <Switch
                                                        checked={row[cell.key]}
                                                        inputProps={{
                                                            'aria-label':
                                                                'controlled',
                                                        }}
                                                    />
                                                )}

                                                {cell.isDate &&
                                                    row[cell.key] && (
                                                        <Moment
                                                            format={
                                                                cell.format
                                                                    ? cell.format
                                                                    : 'MM/DD/YYYY'
                                                            }
                                                        >
                                                            {row[cell.key]}
                                                        </Moment>
                                                    )}
                                                {!cell.isDate &&
                                                    !cell.icon &&
                                                    !cell.overrideCellValueByStr && (
                                                        <>
                                                            {`${
                                                                cell.prefix
                                                                    ? cell.prefix
                                                                    : ''
                                                            }`}
                                                            {row[cell.key]}
                                                        </>
                                                    )}
                                                {!cell.isDate &&
                                                    !cell.icon &&
                                                    cell.overrideCellValueByStr}
                                                {cell.icon &&
                                                    cell.icon ===
                                                        'attachment' &&
                                                    Boolean(row[cell.key]) && (
                                                        <IconButton>
                                                            <AttachFileIcon />
                                                        </IconButton>
                                                    )}
                                                {cell.icon &&
                                                    cell.icon === 'organic' &&
                                                    row[cell.key] && (
                                                        <img
                                                            src={
                                                                '/images/leaf.png'
                                                            }
                                                            alt="organic"
                                                        />
                                                    )}
                                                {cell.icon &&
                                                    cell.icon === 'logbook' &&
                                                    Boolean(row[cell.key]) && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Logbook"
                                                        >
                                                            <LibraryBooksIcon
                                                                sx={{
                                                                    color: '#2196F3',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.icon &&
                                                    cell.icon ===
                                                        'servicebinder' &&
                                                    Boolean(row[cell.key]) && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Service Binder"
                                                        >
                                                            <RoofingIcon
                                                                sx={{
                                                                    color: '#2196F3',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'sdsLinkdata' &&
                                                    row.sdsLink !== null &&
                                                    row.sdsLink?.trim() !==
                                                        '' && (
                                                        <Button
                                                            sx={{
                                                                textTransform:
                                                                    'none',
                                                            }}
                                                            size="medium"
                                                        >
                                                            View
                                                        </Button>
                                                    )}
                                                {cell.key === 'sdsLinkdata' &&
                                                    (row.sdsLink === null ||
                                                        row.sdsLink?.trim() ===
                                                            '') && (
                                                        <Button
                                                            size="medium"
                                                            sx={{
                                                                textTransform:
                                                                    'none',
                                                            }}
                                                            disabled="true"
                                                        >
                                                            View
                                                        </Button>
                                                    )}
                                                {cell.key === 'labelLinkdata' &&
                                                    row.labelLink !== null &&
                                                    row.labelLink.trim() !==
                                                        '' && (
                                                        <Button
                                                            sx={{
                                                                textTransform:
                                                                    'none',
                                                            }}
                                                            size="medium"
                                                        >
                                                            View
                                                        </Button>
                                                    )}
                                                {cell.key === 'labelLinkdata' &&
                                                    (row.labelLink === null ||
                                                        row.labelLink.trim() ===
                                                            '') && (
                                                        <Button
                                                            size="medium"
                                                            sx={{
                                                                textTransform:
                                                                    'none',
                                                            }}
                                                            disabled="true"
                                                        >
                                                            View
                                                        </Button>
                                                    )}
                                                {cell.key === 'otherLinkdata' &&
                                                    row.otherLink !== null &&
                                                    row.otherLink.trim() !==
                                                        '' && (
                                                        <Button
                                                            sx={{
                                                                textTransform:
                                                                    'none',
                                                            }}
                                                            size="medium"
                                                        >
                                                            {row.other
                                                                ? row.other
                                                                : 'N/A'}
                                                        </Button>
                                                    )}
                                                {cell.key === 'otherLinkdata' &&
                                                    (row.otherLink === null ||
                                                        row.otherLink.trim() ===
                                                            '') && (
                                                        <Button
                                                            size="medium"
                                                            sx={{
                                                                textTransform:
                                                                    'none',
                                                            }}
                                                            disabled="true"
                                                        >
                                                            {row.other
                                                                ? row.other
                                                                : 'N/A'}
                                                        </Button>
                                                    )}

                                                {cell.key === 'sdsalert' &&
                                                    !isMobile &&
                                                    (row?.sdsLink === null ||
                                                        row?.sdsLink?.trim() ===
                                                            '') && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Missing"
                                                        >
                                                            <RemoveCircleIcon
                                                                sx={{
                                                                    color: 'warning.main',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'sdsalert' &&
                                                    !isMobile &&
                                                    row?.sdsLink !== null &&
                                                    row?.sdsLink?.trim() !==
                                                        '' && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Current"
                                                        >
                                                            <CheckCircleIcon
                                                                sx={{
                                                                    color: 'green',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}

                                                {cell.key === 'labelalert' &&
                                                    !isMobile &&
                                                    (row?.labelLink === null ||
                                                        row?.labelLink?.trim() ===
                                                            '') && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Missing"
                                                        >
                                                            <RemoveCircleIcon
                                                                sx={{
                                                                    color: 'warning.main',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'labelalert' &&
                                                    !isMobile &&
                                                    row?.labelLink !== null &&
                                                    row?.labelLink?.trim() !==
                                                        '' && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Current"
                                                        >
                                                            <CheckCircleIcon
                                                                sx={{
                                                                    color: 'green',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}

                                                {cell.key === 'otheralert' &&
                                                    !isMobile &&
                                                    (row?.otherLink === null ||
                                                        row?.otherLink?.trim() ===
                                                            '') && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Missing"
                                                        >
                                                            <RemoveCircleIcon
                                                                sx={{
                                                                    color: 'warning.main',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'otheralert' &&
                                                    !isMobile &&
                                                    row?.otherLink !== null &&
                                                    row?.otherLink?.trim() !==
                                                        '' && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Current"
                                                        >
                                                            <CheckCircleIcon
                                                                sx={{
                                                                    color: 'green',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}

                                                {cell.key === 'sdsalert' &&
                                                    isMobile &&
                                                    (row?.sdsLink === null ||
                                                        row?.sdsLink?.trim() ===
                                                            '') && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Missing"
                                                            color="warning"
                                                        />
                                                    )}
                                                {cell.key === 'sdsalert' &&
                                                    isMobile &&
                                                    row?.sdsLink !== null &&
                                                    row?.sdsLink?.trim() !==
                                                        '' && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Current"
                                                            color="success"
                                                        />
                                                    )}

                                                {cell.key === 'labelalert' &&
                                                    isMobile &&
                                                    (row?.labelLink === null ||
                                                        row?.labelLink?.trim() ===
                                                            '') && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Missing"
                                                            color="warning"
                                                        />
                                                    )}
                                                {cell.key === 'labelalert' &&
                                                    isMobile &&
                                                    row?.labelLink !== null &&
                                                    row?.labelLink?.trim() !==
                                                        '' && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Current"
                                                            color="success"
                                                        />
                                                    )}

                                                {cell.key === 'otheralert' &&
                                                    isMobile &&
                                                    (row?.otherLink === null ||
                                                        row?.otherLink?.trim() ===
                                                            '') && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Missing"
                                                            color="warning"
                                                        />
                                                    )}
                                                {cell.key === 'otheralert' &&
                                                    isMobile &&
                                                    row?.otherLink !== null &&
                                                    row?.otherLink?.trim() !==
                                                        '' && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Current"
                                                            color="success"
                                                        />
                                                    )}
                                                {cell.key === 'status_lic' &&
                                                    row.status ===
                                                        'Expired' && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Expired"
                                                        >
                                                            <CancelIcon
                                                                sx={{
                                                                    color: 'error.light',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}

                                                {cell.key === 'status_lic' &&
                                                    row.status === '' &&
                                                    !row.isMissing && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Upto-date"
                                                        >
                                                            <CheckCircleIcon
                                                                sx={{
                                                                    color: 'green',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'status_lic' &&
                                                    row.status ===
                                                        'Expiring Soon' && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Expiring Soon"
                                                        >
                                                            <ErrorIcon
                                                                sx={{
                                                                    color: 'info.light',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'status_lic' &&
                                                    row.isMissing && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Missing"
                                                        >
                                                            <RemoveCircleIcon
                                                                sx={{
                                                                    color: 'warning.main',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'status_tech' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.isExpired && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Expired"
                                                        >
                                                            <CancelIcon
                                                                sx={{
                                                                    color: 'error.light',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'status_tech' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.isExpiringSoon && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Expiring Soon"
                                                        >
                                                            <ErrorIcon
                                                                sx={{
                                                                    color: 'info.light',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'status_tech' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.isMissing && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Missing"
                                                        >
                                                            <RemoveCircleIcon
                                                                sx={{
                                                                    color: 'warning.main',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'status_tech' &&
                                                    (user_details?.type !==
                                                        'sprague' ||
                                                        (!row.isExpired &&
                                                            !row.isExpiringSoon &&
                                                            !row.isMissing)) && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Upto-date"
                                                        >
                                                            <CheckCircleIcon
                                                                sx={{
                                                                    color: 'green',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key ===
                                                    'template_global' &&
                                                    row.templateID && (
                                                        <>
                                                            {row.templateID
                                                                .split(',')
                                                                .map(
                                                                    (
                                                                        id,
                                                                        index
                                                                    ) => {
                                                                        let template_name =
                                                                            row.template.split(
                                                                                ','
                                                                            )[
                                                                                index
                                                                            ];
                                                                        return (
                                                                            <>
                                                                                <Box
                                                                                    sx={{
                                                                                        mr: '6px',
                                                                                    }}
                                                                                    component={
                                                                                        'img'
                                                                                    }
                                                                                    onError={({
                                                                                        currentTarget,
                                                                                    }) => {
                                                                                        currentTarget.onerror =
                                                                                            null;
                                                                                        currentTarget.src = `/images/icon-template.png`;
                                                                                    }}
                                                                                    src={`/api/doc/document-template/${id.trim()}/download`}
                                                                                    alt={
                                                                                        template_name
                                                                                    }
                                                                                />

                                                                                <Box
                                                                                    sx={{
                                                                                        display:
                                                                                            'inline-block',
                                                                                        verticalAlign:
                                                                                            'middle',
                                                                                        mr: '6px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        template_name
                                                                                    }
                                                                                </Box>
                                                                            </>
                                                                        );
                                                                    }
                                                                )}
                                                        </>
                                                    )}
                                                {cell.key === 'status_global' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.isExpired &&
                                                    isMobile && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Expired"
                                                            color="error"
                                                        />
                                                    )}
                                                {cell.key === 'status_global' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.isExpired &&
                                                    !isMobile && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Expired"
                                                        >
                                                            <CancelIcon
                                                                sx={{
                                                                    color: 'error.light',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'status_global' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.isExpiringSoon &&
                                                    !isMobile && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Expiring Soon"
                                                        >
                                                            <ErrorIcon
                                                                sx={{
                                                                    color: 'info.light',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'status_global' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.isExpiringSoon &&
                                                    isMobile && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Expiring Soon"
                                                            style={{
                                                                backgroundColor:
                                                                    '#1976D2',
                                                                color: 'white',
                                                            }}
                                                        />
                                                    )}
                                                {cell.key === 'status_global' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.isMissing &&
                                                    isMobile && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Missing"
                                                            color="warning"
                                                        />
                                                    )}
                                                {cell.key === 'status_global' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.isMissing &&
                                                    !isMobile && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Missing"
                                                        >
                                                            <RemoveCircleIcon
                                                                sx={{
                                                                    color: 'warning.main',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'status_global' &&
                                                    (user_details?.type !==
                                                        'sprague' ||
                                                        (!row.isExpired &&
                                                            !row.isExpiringSoon &&
                                                            !row.isMissing)) &&
                                                    !isMobile && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Current"
                                                        >
                                                            <CheckCircleIcon
                                                                sx={{
                                                                    color: 'green',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'status_global' &&
                                                    (user_details?.type !==
                                                        'sprague' ||
                                                        (!row.isExpired &&
                                                            !row.isExpiringSoon &&
                                                            !row.isMissing)) &&
                                                    isMobile && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Current"
                                                            color="success"
                                                        />
                                                    )}

                                                {cell.key === 'status_ss' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.status === 'Expired' &&
                                                    isMobile && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Expired"
                                                            color="error"
                                                        />
                                                    )}
                                                {cell.key === 'status_ss' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.status === 'Expired' &&
                                                    !isMobile && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Expired"
                                                        >
                                                            <CancelIcon
                                                                sx={{
                                                                    color: 'error.light',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}

                                                {cell.key === 'status_ss' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.status ===
                                                        'ExpiringSoon' &&
                                                    !isMobile && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Expiring Soon"
                                                        >
                                                            <ErrorIcon
                                                                sx={{
                                                                    color: 'info.light',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'status_ss' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.status ===
                                                        'ExpiringSoon' &&
                                                    isMobile && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Expiring Soon"
                                                            style={{
                                                                backgroundColor:
                                                                    '#1976D2',
                                                                color: 'white',
                                                            }}
                                                        />
                                                    )}
                                                {cell.key === 'status_ss' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.status === 'Missing' &&
                                                    isMobile && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Missing"
                                                            color="warning"
                                                        />
                                                    )}
                                                {cell.key === 'status_ss' &&
                                                    user_details?.type ===
                                                        'sprague' &&
                                                    row.status === 'Missing' &&
                                                    !isMobile && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Missing"
                                                        >
                                                            <RemoveCircleIcon
                                                                sx={{
                                                                    color: 'warning.main',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'status_ss' &&
                                                    (user_details?.type !==
                                                        'sprague' ||
                                                        (row.status !==
                                                            'Expired' &&
                                                            row.status !==
                                                                'ExpiringSoon' &&
                                                            row.status !==
                                                                'Missing')) &&
                                                    !isMobile && (
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title="Current"
                                                        >
                                                            <CheckCircleIcon
                                                                sx={{
                                                                    color: 'green',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {cell.key === 'status_ss' &&
                                                    (user_details?.type !==
                                                        'sprague' ||
                                                        (row.status !==
                                                            'Expired' &&
                                                            row.status !==
                                                                'ExpiringSoon' &&
                                                            row.status !==
                                                                'Missing')) &&
                                                    isMobile && (
                                                        <Chip
                                                            sx={{
                                                                margin: '4px',
                                                            }}
                                                            size="small"
                                                            label="Current"
                                                            color="success"
                                                        />
                                                    )}
                                                {cell.key === 'icon_File' && (
                                                    <Tooltip
                                                        arrow
                                                        placement="top"
                                                        title="template"
                                                    >
                                                        <img
                                                            onError={({
                                                                currentTarget,
                                                            }) => {
                                                                currentTarget.onerror =
                                                                    null;
                                                                currentTarget.src = `/images/icon-template.png`;
                                                            }}
                                                            src={
                                                                `/api/doc/document-template/${row.documentTemplateID}/download?filename=${row.iconFile}` ||
                                                                `/images/icon-template.png`
                                                            }
                                                            alt="icon"
                                                        />
                                                    </Tooltip>
                                                )}
                                                {cell.key ===
                                                    'changeLogActivity' && (
                                                    <ChangeLogActivity
                                                        activityString={
                                                            row.description
                                                        }
                                                    />
                                                )}
                                            </TableCell>
                                        ))}
                                    {moreMenu &&
                                    typeof moreMenu.disableFor !==
                                        'undefined' &&
                                    (moreMenu.disableFor === row[primaryKey] ||
                                        (typeof moreMenu.disabledCondition !==
                                            'undefined' &&
                                            !moreMenu.disabledCondition(
                                                moreMenu.disabledConditionKey
                                                    ? row[
                                                          moreMenu
                                                              .disabledConditionKey
                                                      ]
                                                    : row[primaryKey]
                                            ))) ? (
                                        <TableCell align="right"></TableCell>
                                    ) : (
                                        <>
                                            {moreMenu && (
                                                <TableCell align="right">
                                                    <MoreMenu
                                                        section={
                                                            moreMenu.section
                                                        }
                                                        handleClickView={
                                                            moreMenu.onView &&
                                                            typeof moreMenu.onView ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'View'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickEdit={
                                                            moreMenu.onEdit &&
                                                            typeof moreMenu.onEdit ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'Edit'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickDelete={
                                                            moreMenu.onDelete &&
                                                            typeof moreMenu.onDelete ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'Delete'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickDeactivate={
                                                            moreMenu.onDeactivate &&
                                                            typeof moreMenu.onDeactivate ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'Deactivate'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickRequestOrganic={
                                                            moreMenu.onOrganicRequest &&
                                                            typeof moreMenu.onOrganicRequest ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'RequestOrganic'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickAddUserInLocation={
                                                            moreMenu.onAddUser &&
                                                            typeof moreMenu.onAddUser ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'AddUserInLocation'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickAddLocationInUser={
                                                            moreMenu.onAddLocation &&
                                                            typeof moreMenu.onAddLocation ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'AddLocationInUser'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickRemoveLocationInUser={
                                                            moreMenu.onRemoveLocation &&
                                                            typeof moreMenu.onRemoveLocation ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'RemoveLocationInUser'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickApproveOrRejectUser={
                                                            moreMenu.onApproveOrReject &&
                                                            typeof moreMenu.onApproveOrReject ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'ApproveOrRejectUser'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickActivateUser={
                                                            moreMenu.onActivateUser &&
                                                            typeof moreMenu.onActivateUser ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'ActiveUser'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickRequestApprove={
                                                            moreMenu.onApprove &&
                                                            typeof moreMenu.onApprove ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'ApproveOrganicRequests'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickRequestReject={
                                                            moreMenu.onReject &&
                                                            typeof moreMenu.onReject ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'RejectOrganicRequests'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickActivationLink={
                                                            moreMenu.onResendActivationLink &&
                                                            typeof moreMenu.onResendActivationLink ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'SendUserActivationLink'
                                                                      )
                                                                : null
                                                        }
                                                        handleSDSandLabelsOverride={
                                                            moreMenu.onSDSandLabelsOverride &&
                                                            typeof moreMenu.onSDSandLabelsOverride ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'OverrideSDSandLabels'
                                                                      )
                                                                : null
                                                        }
                                                        handleStopOverrideSDS={
                                                            moreMenu.onStopOverrideSDS &&
                                                            typeof moreMenu.onStopOverrideSDS ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'StopOverrideSDS'
                                                                      )
                                                                : null
                                                        }
                                                        handleStopOverrideLabel={
                                                            moreMenu.onStopOverrideLabel &&
                                                            typeof moreMenu.onStopOverrideLabel ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'StopOverrideLabel'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickChangeLog={
                                                            moreMenu.onChangelog &&
                                                            typeof moreMenu.onChangelog ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'ChangeLog'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickLicense={
                                                            moreMenu.onClickLicense &&
                                                            typeof moreMenu.onClickLicense ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'ClickLicense'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickUploadLicense={
                                                            moreMenu.onUploadLicense &&
                                                            typeof moreMenu.onUploadLicense ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'UploadLicense'
                                                                      )
                                                                : null
                                                        }
                                                        handleClickCheckLicense={
                                                            moreMenu.onCheckLicense &&
                                                            typeof moreMenu.onCheckLicense ===
                                                                'function'
                                                                ? () =>
                                                                      handleMoreMenuClick(
                                                                          row[
                                                                              primaryKey
                                                                          ],
                                                                          row,
                                                                          'CheckLicense'
                                                                      )
                                                                : null
                                                        }
                                                        rowData={row}
                                                    />
                                                </TableCell>
                                            )}
                                        </>
                                    )}
                                    {cellActions && (
                                        <TableCell align="right">
                                            {cellActions}
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                        {isPagination && emptyRows > 0 && (
                            <TableRow>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {(loading || data.length === 0) && (
                    <EmptyState loading={loading} />
                )}
            </TableContainer>
            {isPagination && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={total}
                    rowsPerPage={perPage}
                    page={pageNum}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
            {!loading && data.length !== 0 && (
                <LoadMore
                    loading={loading}
                    loadMoreCount={perPage}
                    onChangeLoadCount={(event) =>
                        setPerPage(event.target.value)
                    }
                    onClickLoadMore={onClickLoadMore}
                    total={total}
                    showing={data.length}
                />
            )}
        </Paper>
    );
}

export default DataTable;
