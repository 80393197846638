import React from 'react';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { styled } from '@mui/material/styles';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import {
    FormControl,
    Chip,
    TextField,
    Typography,
    ListSubheader,
    Popper,
} from '@mui/material';

const LISTBOX_PADDING = 8;
function renderRow(props) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
    };

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader
                key={dataSet.locationID}
                component="div"
                style={inlineStyle}
            >
                {dataSet.group}
            </ListSubheader>
        );
    }

    if (itemStatusMap[index] === LOADED) {
        return (
            <Typography
                component="li"
                {...dataSet[0]}
                noWrap
                style={inlineStyle}
            >
                {dataSet[1].address}
            </Typography>
        );
    } else {
        return <Typography style={inlineStyle}>Loading...</Typography>;
    }
}

const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

/* Pagination */
const LOADING = 1;
const LOADED = 2;
let itemStatusMap = {};

const isItemLoaded = (index) => !!itemStatusMap[index];
const loadMoreItems = (startIndex, stopIndex) => {
    for (let index = startIndex; index <= stopIndex; index++) {
        itemStatusMap[index] = LOADING;
    }
    return new Promise((resolve) =>
        setTimeout(() => {
            for (let index = startIndex; index <= stopIndex; index++) {
                itemStatusMap[index] = LOADED;
            }
            resolve();
        }, 200)
    );
};

/* Pagination */

// Adapter for react-window
const ListboxComponent = React.forwardRef(
    function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData = [];

        children.forEach((item) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        });

        /* Pagination */
        const itemCount = itemData.length;
        // const itemSize = smUp ? 36 : 48;
        const itemSize = 36;

        const getChildSize = (child) => {
            if (child.hasOwnProperty('group')) {
                return 48;
            }
            return itemSize;
        };

        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize;
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };

        //const gridRef = useResetCache(itemCount);

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={itemCount}
                        loadMoreItems={loadMoreItems}
                    >
                        {({ onItemsRendered, ref }) => (
                            <VariableSizeList
                                itemData={itemData}
                                itemCount={itemCount}
                                height={getHeight() + 2 * LISTBOX_PADDING}
                                width="100%"
                                ref={ref}
                                outerElementType={OuterElementType}
                                innerElementType="ul"
                                itemSize={(index) =>
                                    getChildSize(itemData[index])
                                }
                                onItemsRendered={onItemsRendered}
                            >
                                {renderRow}
                            </VariableSizeList>
                        )}
                    </InfiniteLoader>
                </OuterElementContext.Provider>
            </div>
        );
    }
);

const CustomChip = (props) => {
    return <Chip {...props} sx={{ color: 'white', zIndex: 51 }} />;
};

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

function MultiSelectPagination({
    label,
    value,
    name,
    onChange,
    options,
    error = null,
    disabled = false,
    variant = 'outlined',
    ...other
}) {
    return (
        <>
            <FormControl
                variant={variant}
                fullWidth
                {...(error && { error: true })}
                {...other}
            >
                <Autocomplete
                    id="virtualize-demo"
                    sx={{ width: 300 }}
                    multiple
                    disableListWrap
                    PopperComponent={StyledPopper}
                    ListboxComponent={ListboxComponent}
                    options={options}
                    getOptionLabel={(option) => option.address}
                    value={value}
                    disableClearable={true}
                    filterSelectedOptions
                    renderInput={(params) => <TextField {...params} />}
                    renderOption={(props, option) => [props, option]}
                    onChange={onChange}
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <CustomChip
                                {...getTagProps({ index })}
                                label={option.address}
                            />
                        ));
                    }}
                    limitTags={2}
                />
            </FormControl>
        </>
    );
}

export default MultiSelectPagination;
