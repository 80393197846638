import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useData } from '../../Context/Context';

function MoreMenu({
    section = '',
    handleClickEdit,
    handleClickView,
    handleClickDeactivate,
    handleClickDelete,
}) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const { userData } = useData(useData);
    const [user_details] = userData;
    const [var_menuEl, set_menuEl] = useState(null);
    const var_menu_open = Boolean(var_menuEl);

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    const handleMenuOpen = (event) => {
        set_menuEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        set_menuEl(null);
    };

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <>
            <IconButton color="primary" onClick={handleMenuOpen}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={var_menuEl}
                open={var_menu_open}
                onClick={handleMenuClose}
                keepMounted
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{ '& .MuiMenu-list': { minWidth: '120px' } }}
            >
                {user_details &&
                    user_details?.permissions[section]?.includes('View') &&
                    handleClickView && (
                        <MenuItem onClick={() => handleClickView()}>
                            View
                        </MenuItem>
                    )}
                {user_details &&
                    user_details?.permissions[section]?.includes('Edit') &&
                    handleClickEdit && (
                        <MenuItem onClick={() => handleClickEdit()}>
                            Edit
                        </MenuItem>
                    )}
                {section === 'Manage Users' &&
                    user_details &&
                    user_details?.permissions[section]?.includes('Patch') &&
                    handleClickDeactivate && (
                        <MenuItem onClick={() => handleClickDeactivate()}>
                            Deactivate
                        </MenuItem>
                    )}
                {user_details &&
                    user_details?.permissions[section]?.includes('Edit') &&
                    handleClickEdit && (
                        <MenuItem onClick={() => handleClickDelete()}>
                            Delete
                        </MenuItem>
                    )}
            </Menu>
        </>
    );
}
//(user_details && user_details?.isImpersonate)
export default MoreMenu;
