import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, FormHelperText, FormControl } from '@mui/material';

const CustomFile = styled(Box)({
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    height: '53px',
});

const CustomFileInput = styled('input')(({ theme }) => ({
    cursor: 'pointer',
    position: 'relative',
    zIndex: '2',
    width: '100%',
    height: '53px',
    margin: '0',
    overflow: 'hidden',
    opacity: '0',
    '&:hover ~ label': {
        borderColor: theme.palette.text.primary,
    },
    '&:focus ~ label': {
        borderColor: theme.palette.primary.main,
        boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
    },
}));

const CustomFileLabel = styled('label')(({ theme }) => ({
    fontSize: '14px',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 16px',
    paddingRight: '90px',
    boxSizing: 'border-box',
    position: 'absolute',
    top: '0',
    right: '0',
    left: '0',
    zIndex: '1',
    height: '53px',
    overflow: 'hidden',
    color: theme.palette.text.primary,
    backgroundColor: '#ffffff',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
        borderColor: theme.palette.text.primary,
    },
    '&::after': {
        content: '"Upload"',
        whiteSpace: 'nowrap',
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        zIndex: '3',
        height: '53px',
        color: '#ffffff',
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        padding: '16px',
        boxSizing: 'border-box',
    },
}));

function file({
    name,
    value,
    multiple = false,
    onChange,
    chosenFile = 'No file chosen',
    helperText,
    error = '',
}) {
    return (
        <FormControl fullWidth>
            <CustomFile>
                <CustomFileInput
                    id={name}
                    name={name}
                    multiple={multiple}
                    type="file"
                    onChange={onChange}
                    value={value}
                />
                <CustomFileLabel htmlFor={name}>
                    <Box
                        sx={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                    >
                        {chosenFile}
                    </Box>
                </CustomFileLabel>
            </CustomFile>
            {(helperText || error) && (
                <FormHelperText error={error !== '' ? true : false}>
                    <span>{error ? error : helperText}</span>
                </FormHelperText>
            )}
        </FormControl>
    );
}

export default file;
