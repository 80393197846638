import React from 'react';
import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';
import { Box } from '@mui/material';
import LogoWhite from '../../logo-blue.svg';

const spinLoader = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

const zooming = keyframes`
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.5);
    }

    100% {
        transform: rotate(1);
    }
`;

const LoaderContainer = styled(Box)({
    backgroundColor: '#fff',
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: '0',
    top: '0',
    zIndex: '1010',
});

const Spinner = styled(Box)({
    border: '16px solid #1c5787',
    borderTop: '16px solid #bd2531',
    borderRadius: '50%',
    width: '120px',
    height: '120px',
    animation: `${spinLoader} 2s linear infinite`,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '-60px',
    marginTop: '-60px',
    boxSizing: 'border-box',
});

const Logo = styled(Box)({
    width: '90px',
    height: '90px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '-45px',
    marginTop: '-45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const StyledImg = styled('img')({
    animation: `${zooming} 5s linear infinite`,
    maxWidth: '100%',
});

function Loader() {
    return (
        <LoaderContainer>
            <Spinner></Spinner>
            <Logo>
                <StyledImg src={LogoWhite} alt="" />
            </Logo>
        </LoaderContainer>
    );
}

export default Loader;
