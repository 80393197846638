import React, { useState, useEffect, Suspense } from 'react';
import { RenderRoutes } from './Routes/Routes';
import AppTopBar from './Components/AppTopBar';
import AppDrawer from './Components/AppDrawer';
import { useData } from './Context/Context';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalNotifications from './Components/ModalNotifications';
import './App.css';
import { useSnackbar } from 'notistack';
import ScrollToTop from './Components/ScrollToTop';
import { Box, Slide, Typography, Container } from '@mui/material';
import ModalPreferences from './Components/ModalPreferences/ModalPreferences';
import IdleTimerContainer from './Components/IdleTimerContainer';
import Loader from './Components/Loader';

function App(props) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const { window: bWindow } = props;
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useNavigate();
    const container =
        bWindow !== undefined ? () => bWindow().document.body : undefined;
    const drawerWidth = 240;
    const {
        frame,
        auth,
        activeSession,
        userData,
        checkAuth,
        drawerOpen,
        loading_data,
    } = useData(useData);
    const [isAuth, setIsAuth] = auth;
    const [, setIsActiveSession] = activeSession;
    const [var_visibleFrame] = frame;
    const [var_drawer_open, set_drawer_open] = drawerOpen;
    const [user_details, setUserDetails] = userData;
    const [, set_loading_data] = loading_data;
    const [var_mdl_notifications_open, set_mdl_notifications_open] =
        useState(false);
    const [var_mdl_preferences_open, set_mdl_preferences_open] =
        useState(false);
    const [varCurrentPage, setCurrentPage] = useState('');
    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        async function checkSession() {
            set_loading_data(true);
            const userInfo = await checkAuth();
            set_loading_data(false);
            if (!userInfo.error) {
                validatePath(location.pathname);
                setIsAuth(true);
                setIsActiveSession(true);
                if (JSON.stringify(userInfo) !== JSON.stringify(user_details)) {
                    setUserDetails(userInfo);
                }
            } else {
                if (user_details) {
                    showSnackbar('error', 'Your session has expired.');
                    history('/', { replace: true });
                }
                setIsAuth(false);
                setUserDetails(null);
                localStorage.clear();
            }
        }
        if (varCurrentPage !== location.pathname) {
            setCurrentPage(location.pathname);
            checkSession();
        }
    }, [location.pathname]);

    function validatePath(path) {
        if (path === '/') {
            history('/app', { replace: true });
        }
    }

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    function showSnackbar(variant, message) {
        enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            TransitionComponent: Slide,
        });
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function handleDrawerToggle() {
        set_drawer_open(!var_drawer_open);
    }

    function openMdlPreferences() {
        set_mdl_preferences_open(true);
    }

    function closeMdlPreferences() {
        set_mdl_preferences_open(false);
    }

    function openMdlNotifications() {
        set_mdl_notifications_open(true);
    }

    function closeMdlNotifications() {
        set_mdl_notifications_open(false);
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <>
            {var_visibleFrame ? (
                <>
                    {isAuth && <IdleTimerContainer />}
                    <Box
                        className="ScpApp"
                        sx={{
                            pt: { xs: '56px', sm: '64px' },
                            pb: '36px',
                            boxSizing: 'border-box',
                            minHeight: '100vh',
                            position: 'relative',
                        }}
                    >
                        {user_details && (
                            <AppTopBar
                                drawerToggle={handleDrawerToggle}
                                openMdlNotifications={openMdlNotifications}
                                closeMdlNotifications={closeMdlNotifications}
                                openMdlPreferences={openMdlPreferences}
                            />
                        )}

                        <Box sx={{ display: 'flex' }}>
                            {user_details && (
                                <AppDrawer
                                    container={container}
                                    drawerWidth={drawerWidth}
                                    open={var_drawer_open}
                                    drawerToggle={handleDrawerToggle}
                                />
                            )}

                            <Box
                                component="main"
                                sx={{ flexGrow: 1, minWidth: '0' }}
                            >
                                <ScrollToTop>
                                    <Suspense fallback={<Loader />}>
                                        <RenderRoutes />
                                    </Suspense>
                                </ScrollToTop>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: '0',
                                left: '0',
                                right: '0',
                                pl: { lg: '240px' },
                                textAlign: 'center',
                                height: '36px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Container maxWidth="xl">
                                <Box sx={{ position: 'relative' }}>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        &copy; 2023 Sprague Pest Solutions
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                sm: 'block',
                                            },
                                            position: { sm: 'absolute' },
                                            right: '0',
                                            top: '50%',
                                            transform: {
                                                sm: 'translateY(-50%)',
                                            },
                                            color: 'rgba(0,0,0,0.28)',
                                        }}
                                    >
                                        Design &amp; developed by Powersoft19
                                    </Typography>
                                </Box>
                            </Container>
                        </Box>
                    </Box>

                    {/***** MODAL: PREFERENCES ********************************************************/}
                    <ModalPreferences
                        openModal={var_mdl_preferences_open}
                        closeModal={closeMdlPreferences}
                    />
                    {/***** END MODAL: PREFERENCES ****************************************************/}

                    {/***** MODAL: NOTIFICATIONS ********************************************************/}
                    <ModalNotifications
                        openModal={var_mdl_notifications_open}
                        closeModal={closeMdlNotifications}
                    />
                    {/***** END MODAL: NOTIFICATIONS ****************************************************/}
                </>
            ) : (
                <>
                    <div className="ScpApp-login">
                        <Suspense fallback={<Loader />}>
                            <RenderRoutes />
                        </Suspense>
                    </div>
                </>
            )}
        </>
    );
}
export default App;
