import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// Methods--------------------------------------------------------------------------/
function BasicDatePicker({
    name,
    label,
    value,
    onChange,
    clearBtn = false,
    error,
    disabled = false,
    ...other
}) {
    const convertToDefaultEventPara = (name, value) => ({
        target: {
            name,
            value,
        },
    });
    const [open, setOpen] = useState(false);

    // Methods End------------------------------------------------------------------------/

    // Render APP-------------------------------------------------------------------/
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    name={name}
                    label={label}
                    value={value}
                    disabled={disabled}
                    slotProps={{
                        textField: {
                            readOnly: true,
                            onClick: (e) => setOpen(true),
                        },
                    }}
                    views={['year', 'month', 'day']}
                    onAccept={(date) =>
                        onChange(convertToDefaultEventPara(name, date))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={false}
                            // {...(error && {
                            //     error: true,
                            //     helperText: error,
                            // })}
                            fullWidth
                        />
                    )}
                />
            </LocalizationProvider>
        </>
    );
}
// App Component End-------------------------------------------------------------------/

export default BasicDatePicker;
